import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { TurnoService } from 'src/app/core/auth/turno.service';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { FormControlService } from '../../services/form-control.service';
import { ConfirmationService } from '../../services/confirmation.service';
import { VentaService } from 'src/app/modules/venta';
import { ToastrService } from 'ngx-toastr';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { InfoToast } from 'src/app/core/toast/info.toast';
import { mergeMap, map, takeWhile, takeUntil } from 'rxjs/operators';
import { PrintService } from '../../services/print.service';
import { MyCurrencyPipe } from '../../pipes/myCurrency.pipe';
import * as moment from 'moment';
import 'moment/min/locales';
import { ConfigurationService } from 'src/app/core';
import { HttpClient } from '@angular/common/http';
import { AppConfiguration } from 'src/app/app.configuration';
import { Subscription } from 'rxjs';
import { DivisaObject } from '../../interfaces/global.interface';

@Component({
  selector: 'ticketing-cerrar-turno',
  templateUrl: './cerrar-turno.component.html',
  styleUrls: ['./cerrar-turno.component.scss']
})
export class CerrarTurnoComponent implements OnInit, OnDestroy {
  datosInforme;
  usuarios;
  productosVenta;
  comentarios;
  currentDate = moment().format('YYYY-MM-DD HH:mm');
  private alive = true;
  window = window as any;
  disabledAceptar = false;
  cerrarTurnoSub: Subscription
  cierreTurno: FormGroup;
  @Input() id: string;
  @ViewChild('efectivo') clientInput: ElementRef;
  private element: any;

  placeholderValue: string;
  divisaSimboloDecimal: string; 
  divisaSimbSeparacionMiles: string;  
  divisaSimbolo: string;
  divisaDecimales: number;
  divisaPosicion: string;
  divisaSimboloDerecha: string;

  divisa: DivisaObject;
  subscription: Subscription;

  constructor(
    private modalService: ModalService,
    private el: ElementRef,
    private formBuilder: FormBuilder,
    private formControlService: FormControlService,
    private turnoService: TurnoService,
    private userService: UserManagerService,
    private confirmationService: ConfirmationService,
    private ventaService: VentaService,
    private toast: ToastrService,
    private printService: PrintService,
    private myCurrencyPipe: MyCurrencyPipe,
    private configuration: ConfigurationService,
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.cierreTurno = this.formBuilder.group({
      efectivo: [
        '',
        Validators.compose([
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
        ])
      ],
      // bolsaCambio: [
      //   '',
      //   Validators.compose([
      //     Validators.maxLength(10),
      //     Validators.pattern('^[a-zA-Z0-9]*$')
      //   ])
      // ],
      comentario: [
        '',
        Validators.compose([
          Validators.maxLength(50),
          Validators.pattern('^[a-zA-Z0-9á-ý\\u00f1\\u00d1\\s]*$')
        ])
      ]
    });

    const modal = this;

    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed abovse everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });

    this.subscription = this.configuration.divisas.subscribe(divisa => {
      if(divisa) {
        //this.divisaSimbSeparacionMiles = this.divisa[0].SimbSeparacionMiles;
        this.divisaSimboloDecimal = divisa[0].SimboloDecimal;
        this.divisaDecimales = Number(divisa[0].NumeroDecimales)
        this.divisaPosicion = divisa[0].PosicionDivisa;
        this.divisaSimbolo = divisa[0].simbolo;
        this.divisaSimboloDerecha = divisa[0].SimboloDerecha;
        this.placeholderValue = this.myCurrencyPipe.transform(0, divisa[0].SimboloDecimal, divisa[0].NumeroDecimales, divisa[0].PosicionDivisa, divisa[0].simbolo, divisa[0].SimboloDerecha)
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
    // this.onChanges();
  }
  ngOnDestroy() {
    this.formControlService.unfocus();
  }

  open(): void {
    this.alive = true;
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.alive = false;
    this.disabledAceptar = false;
    this.formControlService.unfocus();
    if(this.cerrarTurnoSub){this.cerrarTurnoSub.unsubscribe();}
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');

  }

  focusValue(event: any) {
    this.formControlService.setCurrentFormGroup(
      this.cierreTurno,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  deleteValue() {
    this.formControlService.setToBlank();
  }

  isSelected(element) {
    return this.formControlService.currentFormGroupValue &&
    this.formControlService.currentFormGroupValue.elemento &&
      element.id === this.formControlService.currentFormGroupValue.elemento.id
      ? true
      : false;
  }

  cerrarTurno() {
    if (this.checkFormIsInvalid()) {
      return;
    }
    this.disabledAceptar = true;
    const efectivo =
      Number.parseFloat((this.cierreTurno.controls['efectivo'].value).replace(',','.')) || 0;
    this.comentarios = this.cierreTurno.controls['comentario'].value || '';
    this.modalService.open('confirmarCierreTurno');
    this.cerrarTurnoSub = this.confirmationService.confirmado
      .subscribe(confirmado => {
        // 
        if (confirmado) {
          this.turnoService
            .cerrarCaja(efectivo, this.comentarios)
            .pipe(takeWhile(() => this.alive))
            .subscribe(async (res: any) => {
              if (!res.DatosResult) {
                this.closeModals();
                this.toast.error(res.Mensajes[0].DescripcionMensaje, 'Error', {
                  toastComponent: ErrorToast,
                  timeOut: 5000
                });
                this.cerrarTurnoSub.unsubscribe();
                return;
              }   
              if(this.configuration.appConfig.value.DatosResult.AppConfigJsonTurno.TurnoCierreInformePorUsuario == 1){
                const turnoValue = this.turnoService.turnoValue;
                let cantidadImpresiones = 0;
                if (this.configuration.deviceConfiguration.AppConfigJsonTurno && this.configuration.deviceConfiguration.AppConfigJsonTurno.CopiasCierre != null && this.configuration.deviceConfiguration.AppConfigJsonTurno.CopiasCierre.trim() != '')
                {
                  cantidadImpresiones = this.configuration.deviceConfiguration.AppConfigJsonTurno && this.configuration.deviceConfiguration.AppConfigJsonTurno.CopiasCierre? Number.parseInt(this.configuration.deviceConfiguration.AppConfigJsonTurno.CopiasCierre): 0;
                }    
                this.turnoService.generarInformeCierreV2(this.turnoService.cajaValue.CajaId, turnoValue.TpvId, cantidadImpresiones).subscribe()
              }   
              this.turnoService.getDatosTPVPath().subscribe();  
              this.datosInforme = res.datosInforme;
              this.usuarios = res.datosInforme? res.datosInforme.ListadoCajaUsuarios: null;
              this.productosVenta = res.datosInforme? res.datosInforme.ListadoCajaProductos: null;
              this.closeModals();
              this.reset();
              this.turnoService.setTurnoValue(undefined);
              this.toast.info('', 'Caja cerrada', {
                toastComponent: InfoToast,
                timeOut: 5000
              });
              this.configuration.dataReady.next(true);
              //Metemos un bucle segun configuracion 
                   
              // //Cargamos de configuracion
              // setTimeout(() => {
              //   if(cantidadImpresiones > 0){
              //     for (let i = 0; i < cantidadImpresiones; i++) {
              //       this.printService.printCashClosureReport(this.datosInforme);
              //     }
              //   }               
              // }, 5000);
              // if (
              //   this.window.external &&
              //   typeof this.window.external.salir === 'function'
              // ) {
              //   //Prueba con delay
              //   await this.delay(10000);
              //   this.window.external.salir();
              // }
              this.cerrarTurnoSub.unsubscribe();
            });
        }
      });
  }

  closeModals() {
    this.modalService.close('confirmarCierreTurno');
    this.modalService.close('cerrarTurno');
    this.modalService.close('gestionarTurno');
    this.close();
  }

  reset() {
    
    //Cargamos el tpv original 
    const config = this.configuration.getTPVPathOrigenValue();    
    this.configuration.setTpvPathLocal(config);
    //fin de cargamos tpv original
    this.userService.logout();
    this.ventaService.clearCarrito();
    this.turnoService.setTurnoValue(undefined);
    this.confirmationService.setConfirmado(false);
    this.userService.setCurrentUser(null);
    this.turnoService.setCambioValue(null);
    this.turnoService.setTurnoValue(null);
  }

  checkFormIsInvalid() {
    if (this.cierreTurno && this.cierreTurno.invalid) {
      return true;
    }
    if(this.disabledAceptar)
    {
      return true;
    }
    return false;
  }

  calculaPrecioUnitario(total, cantidad) {
    const unidad =
      Number.parseFloat(total.replace(',', '.')) /
      Number.parseFloat(cantidad.replace(',', '.'));
    return this.myCurrencyPipe.transform(unidad, this.divisaSimboloDecimal, this.divisaDecimales, this.divisaPosicion, this.divisaSimbolo, this.divisaSimbSeparacionMiles);
  }
  calculaTotalNumVentas() {
    let total = 0;
    this.productosVenta.forEach(producto => {
      total = total + Number(producto.ProductoCantidadVenta);
    });
    return this.myCurrencyPipe.transform(total, this.divisaSimboloDecimal, this.divisaDecimales, this.divisaPosicion, this.divisaSimbolo, this.divisaSimbSeparacionMiles);
  }

  calculaTotalDevoluciones() {
    let total = 0;
    this.productosVenta.forEach(producto => {
      total = total + Number(producto.ProductoCantidadDevolucion);
    });
    return this.myCurrencyPipe.transform(total, this.divisaSimboloDecimal, this.divisaDecimales, this.divisaPosicion, this.divisaSimbolo, this.divisaSimbSeparacionMiles);
  }

  calculaTotalReservas() {
    let total = 0;
    this.productosVenta.forEach(producto => {
      total = total + Number(producto.ProductoCantidadReserva);
    });
    return this.myCurrencyPipe.transform(total, this.divisaSimboloDecimal, this.divisaDecimales, this.divisaPosicion, this.divisaSimbolo, this.divisaSimbSeparacionMiles);
  }

  calculaCantidadTotal() {
    let total = 0;
    this.productosVenta.forEach(producto => {
      total = total + Number(producto.ProductoCantidad);
    });
    return this.myCurrencyPipe.transform(total, this.divisaSimboloDecimal, this.divisaDecimales, this.divisaPosicion, this.divisaSimbolo, this.divisaSimbSeparacionMiles);
  }

  calculaTotalImporteVentas() {
    let total = 0;
    this.productosVenta.forEach(producto => {
      total =
        total +
        Number.parseFloat(producto.ProductoImporteTotal.replace(',', '.'));
    });
    return this.myCurrencyPipe.transform(total, this.divisaSimboloDecimal, this.divisaDecimales, this.divisaPosicion, this.divisaSimbolo, this.divisaSimbSeparacionMiles);
  }

  calculaHora(fecha) {
    const hora = fecha.split(' ')[1];
    const [h, m, s] = hora.split(':');
    return h + ':' + m;
  }

  calculaFecha(fecha) {
    return fecha.split(' ')[0];
  }

  delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
  }
  addDotsToNumber(number, decimalLength = 2) {
    number = '' + number;
    number.replace('.', ',');
    if (number.includes(',')) {
      // tslint:disable-next-line:prefer-const
      let [integer, decimal] = number.split(',');
      if (integer.length > 3) {
        const integerArray = integer.split('');
        let newInteger = '';
        integerArray.forEach((num, index) => {
          if ((integerArray.length - index) % 3 === 0) {
            newInteger += '.';
          }
          newInteger += num;
        });
        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        return newInteger;
      } else {
        let newInteger = integer;

        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        return newInteger;
      }
    } else if (number.length > 3) {
      const integerArray = number.split('');
      let newInteger = '';
      integerArray.forEach((num, index) => {
        if ((integerArray.length - index) % 3 === 0) {
          newInteger += '.';
        }
        newInteger += num;
      });
      let decimal = ',';
      while (decimal.length <= decimalLength) {
        decimal += '0';
        // 
      }
      newInteger += decimal;
      return newInteger;
    }
    // 
    let d = ',';
    for (let i = 0; i < decimalLength; ++i) {
      d += '0';
    }
    return number + d;
  }
}
