import { Injectable } from '@angular/core';
import { Entrada } from '../models/entrada.model';
import { HttpClient } from '@angular/common/http';
import {
  share,
  mergeMap,
  shareReplay,
  distinctUntilChanged,
  debounceTime,
  switchMap,
  map,
  take
} from 'rxjs/operators';
import { from, Observable, BehaviorSubject } from 'rxjs';
import { AppConfiguration } from 'src/app/app.configuration';
import { ConfigurationService } from 'src/app/core/conf/configuration.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class EntradasService {
  entradas: Entrada[];

  private entradaSeleccionadaSubject: BehaviorSubject<any>;
  public entradaSeleccionada$: Observable<any>;

  constructor(
    private config: AppConfiguration,
    private http: HttpClient,
    private configuration: ConfigurationService
  ) {
    
    this.entradaSeleccionadaSubject = new BehaviorSubject(undefined);
    this.entradaSeleccionada$ = this.entradaSeleccionadaSubject.asObservable();
  }

  public get entradaSeleccionada() {
    return this.entradaSeleccionadaSubject.value;
  }

  public setEntradaSeleccionada(entrada) {
    this.entradaSeleccionadaSubject.next(entrada);
  }

  getCategorias(cliente?, fecha?) {
    const config = this.configuration.appConfig.getValue();
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    // 
    const body = {
      ConexionIacpos: conexionId,
      TPVId: this.configuration.datosTPVPathValue.pkId,
      CodigoCliente: cliente ? cliente : '0',
      FiltrarPorCliente:   config.DatosResult.AppConfigJsonConfig &&
        config.DatosResult.AppConfigJsonConfig
          .ClienteFiltrarProductosPorCliente,
      Fecha: fecha != null && fecha !== '' ? moment(fecha).format('YYYY/MM/DD') : ''
    };
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/GetKoreCategorias`, body)
      .pipe(distinctUntilChanged())
      .pipe(shareReplay(1))
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            return [];
          }
          return res.DatosResult.ListadoCategoriasDisponibles;
        })
      );
  }

  buscar(terms: Observable<any>, fecha, cliente, categoriaId) {
    return terms
      .pipe(debounceTime(400))
      .pipe(switchMap(() => this.getEntradas(categoriaId, fecha, cliente)));
  }

  getEntradas(
    categoriaId: string,
    fecha?,
    cliente?,
    limite = '9',
    pagina = '1',
    tipoProducto?,
    filtroTipoProductoCod?,
    filtroGrupoAsociadoCod?,
    filtroTipoNivelCod?,
    texto?
  ) {
    const config = this.configuration.appConfig.getValue();
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    let body = {
      ConexionIacpos: conexionId,
      TPVId: this.configuration.datosTPVPathValue.pkId,
      TipoProducto: tipoProducto,
      CategoriaId: categoriaId,
      TipoProductoCod: filtroTipoProductoCod,
      GrupoAsociadoCod: filtroGrupoAsociadoCod,
      TipoNivelCod: filtroTipoNivelCod,
      FiltrarPorCliente:
        config.DatosResult.AppConfigJsonConfig.ClienteFiltrarProductosPorCliente,
      Texto: texto
    };
    if (fecha) {
      body = Object.assign(body, { Fecha: moment(fecha).format('YYYY/MM/DD') });
    }

    /*     if (cliente) {
      if (cliente.Codigo === this.configuration.internetConfig.DatosResult.ClienteId) {
        body = Object.assign(body, { CodigoCliente: '0' });
      } else {
        body = Object.assign(body, { CodigoCliente: cliente.Codigo });
      }
    } else {
      body = Object.assign(body, { CodigoCliente: '0' });
    } */

    body = Object.assign(body, {
      CodigoCliente: cliente ? cliente.Codigo : '0'
    });
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/GetKoreTarifasDisponibles`,
        body
      )
      .pipe(distinctUntilChanged())
      .pipe(shareReplay(1))
      .pipe(
        map((res: any) => {
          if (res.DatosResult === null) {
            return {};
          }
          return res.DatosResult.ListadoKoreTarifasDisponibles;
        })
      );
  }

  getEntradasBotonera(fecha?, cliente?, limite = '24', pagina = '1') {
    const modoPresentacion = this.configuration.appConfig.value.DatosResult
    .AppConfigJsonConfig && this.configuration.appConfig.value.DatosResult
    .AppConfigJsonConfig.ModoPresentacion ?  this.configuration.appConfig.value.DatosResult
      .AppConfigJsonConfig.ModoPresentacion : null;
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    let body = {
      ConexionIacpos: conexionId,
      TPVId: this.configuration.datosTPVPathValue.pkId,
      Botonera: '1'
    };
    if (fecha) {
      body = Object.assign(body, { Fecha: fecha.format('YYYY/MM/DD') });
    }
    if (cliente) {
      body = Object.assign(body, {
        CodigoCliente: cliente ? cliente.Codigo : '0'
      });
    }

    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/GetKoreTarifasDisponibles`,
        body
      )
      .pipe(distinctUntilChanged())
      .pipe(shareReplay())
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            return [];
          } else if (modoPresentacion === 'BOTONERAFIJA') {
            const tarifas = res.DatosResult.ListadoKoreTarifasDisponibles;
            const posiciones = tarifas.map(t => +t.Orden);
            const posicionMaxima = Math.max(...posiciones);
            const arrTarifas = new Array<any>(posicionMaxima).fill(undefined);
            tarifas.forEach(t => {
              arrTarifas[+t.Orden - 1] = t;
            });
            return arrTarifas;
          } else if (modoPresentacion === 'BOTONERA') {
            const tarifas = res.DatosResult.ListadoKoreTarifasDisponibles;
            return tarifas;
          }
        })
      );
  }

  recalcularPrecio(tpvId, entradas, horaVisita, fecha?, cliente?) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    let entradasString: String = '';
    if (entradas) {
      entradas.forEach(entrada => {
        // const datosEntrada = entrada.split(',');
        // entradasString += '[' + datosEntrada[0] + ',' + datosEntrada[1] + '],';
        entradasString += '[' + entrada + '],';
      });
      entradasString = entradasString.substring(0, entradasString.length - 1);
    }
    const body = {
      ConexionIacpos: conexionId,
      TPVId: tpvId,
      Fecha: '' || fecha.format('YYYY/MM/DD'),
      // TipoProducto: '1',
      CodigoCliente: cliente ? cliente.Codigo : '0',
      FiltrarPorCliente: 0,
      Items: entradasString,
      HoraVisita: horaVisita + ':00'
    };
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/GetKoreTarifasDisponibles`,
        body
      )
      .pipe(
        map((res: any) => {
          if (res.DatosResult) {
            return res.DatosResult.ListadoKoreTarifasDisponibles;
          }
          return [];
        })
      );
  }

  recalcularCarrito(entradas, fecha?) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TPVId: this.configuration.datosTPVPathValue.pkId,
      Fecha: '' || moment(fecha).format('YYYY/MM/DD'),
      Items: entradas
    };
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/GetKoreTarifasDisponibles`,
        body
      )
      .pipe(
        map((res: any) => {
          if (res.DatosResult) {
            return res.DatosResult.ListadoKoreTarifasDisponibles;
          }
          return [];
        })
      );
  }

  comprobarGrupoMotivoDescuentoSeleccionable(tipoEntradaId){

     return this.getGrupoMotivoDescuento(tipoEntradaId).pipe(take(1)).pipe(map ((datos: any)=>{ 
      if(datos && datos.DatosResult)
      {
        
        
        return true;
      }else{
        
        
        return false;
      }
      }));
  }

  getGrupoMotivoDescuento(tiposEntrada: string) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TiposEntrada: tiposEntrada
    };
    return this.http
      .post(
        `${this.config.getConfig(
          'API_URL'
        )}api/Configuracion/GetGrupoMotivoSeleccionableXEntrada`,
        body
      )
      .pipe(shareReplay(1));
  }

  getRecintosEntrada(tiposEntrada: any) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TiposEntrada: tiposEntrada
    };
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/GetRecintosEntrada`, body)
      .pipe(shareReplay(1));
  }

  getSesionesEntreFechas(
    fechaDesde: string,
    fechaHasta: string,
    codigoRecintos: any,
    listadoProductos: any
  ) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const identificadorUnico = this.configuration.datosTPVPathValue.pkId;
    const body = {
      ConexionIacpos: conexionId,
      FechaDesde: fechaDesde,
      FechaHasta: fechaHasta,
      Recinto: codigoRecintos,
      listadoProductos: listadoProductos,
      IdentificadorUnico: identificadorUnico
    };
    return this.http
      .post(
        `${this.config.getConfig(
          'API_URL'
        )}api/Configuracion/TraerSesionesEntreFechas`,
        body
      )
      .pipe(shareReplay(1));
  }

  getDescuentos(grupoID: number, seleccionable?: number) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      GrupoId: grupoID,
      Seleccionable: seleccionable? seleccionable: 2
    };
    return this.http
      .post(
        `${this.config.getConfig(
          'API_URL'
        )}api/Customan/ObtenerCustomanItemsMotivosGrupos`,
        body
      )
      .pipe(shareReplay(1))
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            return null;
          }
          return res.DatosResult.customanItemMotGrupo;
        })
      );
      
  }

  getDescuentosPrueba(listaIds) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    return from(listaIds).pipe(
      mergeMap(id => {
        const body = {
          ConexionIacpos: conexionId,
          GrupoId: id
        };
        return <Observable<any>>(
          this.http
            .post(
              `${this.config.getConfig(
                'API_URL'
              )}api/Customan/ObtenerCustomanItemsMotivosGrupos`,
              body
            )
            .pipe(shareReplay(1))
        );
      })
    );
  }

  //Tipos paraa Filtros
  getFiltroTiposProducto(filtro?: string) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Filtro: filtro
    };
    return this.http
      .post(
        `${this.config.getConfig(
          'API_URL'
        )}api/Configuracion/GetTiposProductos`,
        body
      )
      .pipe(shareReplay(1));
  }

  getFiltroGruposAsociado(filtro?: string) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Filtro: filtro
    };
    return this.http
      .post(
        `${this.config.getConfig(
          'API_URL'
        )}api/Configuracion/GetGruposAsociados`,
        body
      )
      .pipe(shareReplay(1));
  }

  getFiltroTiposNiveles(filtro?: string) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Filtro: filtro
    };
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/Configuracion/GetTiposNiveles`,
        body
      )
      .pipe(shareReplay(1));
  }

}
