import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ElementRef,
  ViewChild,
  ɵConsole,
  AfterViewChecked
} from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClienteService } from '../../services/cliente.service';
import { PagerService } from '../../services/pager.service';
import { VentaService } from 'src/app/modules/venta/services/venta.service';
import { FormControlService } from '../../services/form-control.service';
import { Subscription } from 'rxjs';

interface Cliente {
  pkId: string;
  nombreCliente: string;
  codigo: string;
  nombreComercial: string;
}

@Component({
  selector: 'ticketing-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() id: string;
  @ViewChild('clientInput') clientInput: ElementRef;
  private element: any;
  clientForm: FormGroup;
  clientes = [];

  pager: any = {};
  pagedItems: any[];

  myFormSub: Subscription;

  constructor(
    private modalService: ModalService,
    private el: ElementRef,
    private formBuilder: FormBuilder,
    private formControlService: FormControlService,
    private clienteService: ClienteService,
    private pagerService: PagerService,
    private ventaService: VentaService
  ) {
    this.element = el.nativeElement;
    this.clientForm = this.formBuilder.group({
      searchField: ''
    });
  }

  ngOnInit(): void {
    const modal = this;
    if (!this.id) {
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  onChanges() {
    this.myFormSub = this.clienteService
      .buscar(this.clientForm.valueChanges)
      .subscribe((res: any) => {
        this.clientes = res || [];
        this.setPage(1);
      });
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.clientes.length, page);
    this.pagedItems = this.clientes.slice(this.pager.startIndex, this.pager.endIndex + 1);

    this.pager.pages = this.pager.pages.filter(num => num <= this.pager.totalPages);

    while (this.pager.pages.length < 6 && this.pager.pages[0] > 1) {
      this.pager.pages.unshift(this.pager.pages[0] - 1);
    }
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  ngAfterViewChecked(): void {
    this.clientInput.nativeElement.focus();
  }
  open(): void {
    this.initFocus();
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.resetFocus();
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  private initFocus() {
    this.formControlService.setCurrentFormGroup(
      this.clientForm,
      this.clientInput.nativeElement,
      this.clientInput.nativeElement.getAttribute('category')
    );
    if (this.ventaService.clienteSeleccionadoValue) {
      this.formControlService.currentFormGroupValue.form.controls['searchField'].patchValue(
        this.ventaService.clienteSeleccionadoValue.NombreCliente
      );
    } else {
      this.formControlService.currentFormGroupValue.form.controls['searchField'].patchValue('');
    }
    //this.clientInput.nativeElement.focus();
    this.onChanges();
  }

  private resetFocus() {
    if(this.myFormSub){this.myFormSub.unsubscribe();}
    this.formControlService.unfocus();
    this.clientes = [];
    this.pagedItems = [];
  }

  seleccionarCliente(cliente) {
    this.ventaService.currentCliente = cliente.Codigo;
    this.ventaService.setClienteSeleccionadoValue(cliente);
    this.clientForm.controls['searchField'].patchValue(cliente.NombreCliente);
    this.close();
  }

  focusValue(event: Event) {
    this.formControlService.setCurrentFormGroup(
      this.clientForm,
      this.clientInput.nativeElement,
      // TODO confirmar que el tipado funciona
      (<HTMLElement>event.target).getAttribute('category')
    );
  }

  deleteValue() {
    this.formControlService.setToBlank();
    this.clientes = [];
    this.pager = {};
    this.pagedItems = [];
    // this.clientForm.reset();
    this.ventaService.setClienteSeleccionadoValue('');
    this.ventaService.currentCliente='0'
  }

  isSelected(element) {
    return this.formControlService.currentFormGroupValue &&
    this.formControlService.currentFormGroupValue.elemento &&
      element.id === this.formControlService.currentFormGroupValue.elemento.id
      ? true
      : false;
  }
}
