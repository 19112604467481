import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ticketing-cell',
  templateUrl: './cell.component.html',
  styleUrls: ['./cell.component.scss']
})
export class CellComponent implements OnInit {

  @Input() color = '';
  @Input() time;
  @Input() ticketsLeft;

  @Input() isNaranja = false;
  @Input() isSelect = false;
  @Input() isRojo = false;
  @Input() isApagado = false;
  @Input() isMas = false;
  @Input() isSeparadorProfesores = false;
  @Input() idioma;
  @Input() tipoAforo: string;

  @Output() toggle: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    if (this.isApagado) {
      this.isRojo = false;
      this.isNaranja = false;
      this.isSelect = false;
      this.isMas = false;
      this.isSeparadorProfesores = false;
    }
    switch (this.color) {
      case 'naranja': this.isNaranja = true; break;
      case 'rojo': this.isRojo = true; break;
      case 'apagado': this.isApagado = true; break;
      case 'mas': this.isMas = true; break;
      case 'separadorprofesores' : this.isSeparadorProfesores = true; break;
    }
  }
}
