import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from '../../conf/configuration.service';
import { UserManagerService } from '../user-manager.service';
import { TurnoService } from '../turno.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { SuccessToast } from '../../toast/success.toast';
import { ErrorToast } from '../../toast/error.toast';
import { mergeMap, map } from 'rxjs/operators';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { AppConfiguration } from 'src/app/app.configuration';
import { ModalService } from 'src/app/shared/services/modal.service';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { LoginService } from '../login.service';
import { DesglosarCambioService } from 'src/app/modules/gestionar/services/desglosar-cambio.service';
import { ThrowStmt } from '@angular/compiler';
import { InfoToast } from '../../toast/info.toast';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { VisorService } from 'src/app/shared/services/visor.service';
import { TokenManagerService } from '../token-manager.service';
import { SidebarNavigationService } from 'src/app/shared/services/sidebar-navigation.service';
import { DeviceConfiguration } from '../../conf/DeviceConfiguration';

@Component({
  selector: 'ticketing-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host: {
    class: 'layout__cambiar-usuario',
  },
})
export class LoginComponent implements OnInit, OnDestroy {
  appConfig = new BehaviorSubject(undefined);
  filtrarPorCliente: string;
  maxNumImpresiones: any;
  private deviceConfigurationSubject: BehaviorSubject<DeviceConfiguration>;
  error;
  loging = false;
  loginForm: FormGroup;
  dataReady = false;
  window = window as any;
  cambiarPass = true;
  importeInicial = '0,00';
  cajaIniciada = false;
  disabledEntrar = false;
  esperarSub: Subscription;
  tpvEnUso = false;
  tpvSelecciconado = false;
  listadoTPVDisponibles;
  datosTPVPathSub: Subscription;
  tpvEnUsoSub: Subscription;
  listadoTPVsEnUsoSub: Subscription;
  tpvSeleccionadoSub: Subscription;
  loginSub: Subscription;
  cambioInicialSub: Subscription;
  dataReadySub: Subscription;
  loginFormSub: Subscription;
  title: string;
  private subscriptions: Subscription[] = [];
  public url = this.router.url;
  constructor(
    private configuration: ConfigurationService,
    private turnoService: TurnoService,
    private formBuilder: FormBuilder,
    private formControlService: FormControlService,
    private router: Router,
    private userService: UserManagerService,
    private toastr: ToastrService,
    private config: AppConfiguration,
    private modalService: ModalService,
    private translate: TranslateService,
    private loginService: LoginService,
    private desgloseService: DesglosarCambioService,
    private titleService: Title,
    private route: ActivatedRoute,
    private http: HttpClient,
    private visor: VisorService,
    private tokenManager: TokenManagerService,
    private sidebarNav: SidebarNavigationService
  ) {
    if (this.route.snapshot.paramMap.get('path')) {
      this.loginService.getInitData();
      const traerConexionPrivadaBody = {
        chClaveEmpresa: this.config.getConfig('chClaveEmpresa'),
        chNombreProyecto: this.config.getConfig('chNombreProyecto'),
        chEntornoConexion: this.config.getConfig('chEntornoConexion'),
        chOrigenConexion: this.config.getConfig('chOrigenConexion'),
      };
      this.http
        .post(
          `${this.config.getConfig('API_URL')}api/TraerConexionPrivada`,
          traerConexionPrivadaBody
        )
        .subscribe((data: any) => {
          this.tokenManager.currentConexionIdSubject.next(data.pkid);
          localStorage.setItem('conexionId', JSON.stringify(data.pkid));
          this.tokenManager
            .login(
              // this.config.getConfig('USER'),
              // this.config.getConfig('PASSWORD')
              this.config.getConfig('NUMSERIE')
            )
            .pipe(
              mergeMap((res) => {
                //obtenemos el tpvPath de la aplicacion
                this.configuration.getTPVPathLocal();
                //asignamos el tpv obtenido desde el servicio
                let config = this.configuration.tpvPathLocalValue;
                //comprobamos si el tpv esta en uso para cargar los disponibles si no lo esta
                if (!this.configuration.tpvFisicoValue) {
                  this.configuration.getTPVEnUso(config).subscribe();
                }
                // if (
                //   this.window.external &&
                //   typeof this.window.external.obtenerEquipo === 'function'
                // ) {
                //   config = this.window.external.obtenerEquipo();
                // } else if (this.route.snapshot.queryParams.path) {
                //   config = this.route.snapshot.queryParams.path;
                // } else {
                //   config = this.config.getConfig('TPV_PATH');
                // }
                return this.http
                  .post(
                    `${this.config.getConfig(
                      'API_URL'
                    )}api/Configuracion/GetDatosTPVPath`,
                    {
                      ConexionIacpos: data.pkid,
                      VMPath: this.configuration.getTPVPathOrigenValue(
                        this.route.snapshot.paramMap.get('path')
                      ),
                    }
                  )
                  .pipe(
                    map((TPVPath: any) => {
                      this.configuration.setDatosTPVPathValue(
                        TPVPath.DatosResult
                      );
                      return TPVPath.DatosResult;
                    })
                  );
              })
            )
            .pipe(
              mergeMap((res: any) => {
                return this.configuration.getAppConfig(res.pkId).pipe(
                  map(() => {
                    return res;
                  })
                );
              })
            )
            .pipe(
              mergeMap((res) => {
                return this.configuration.getModulos().pipe(
                  map((modulos) => {
                    this.sidebarNav.setSecciones(modulos);
                    return res;
                  })
                );
              })
            )
            .subscribe(() => {
              this.http
                .post(
                  `${this.config.getConfig(
                    'API_URL'
                  )}api/Configuracion/GetDatosInicio`,
                  {
                    ConexionIacpos: data.pkid,
                  }
                )
                .subscribe((res: any) => {
                  this.visor.setToBlank();
                  this.http
                    .post(
                      `${this.config.getConfig(
                        'API_URL'
                      )}api/Maestros/GetDivisas`,
                      {
                        ConexionIacpos: data.pkid,
                        ISO: res.DatosResult.DivisaISO,
                      }
                    )
                    .subscribe((infoDivisa: any) => {
                      this.configuration.getInternetConfig().subscribe();
                      this.configuration.setDatosInicioValue(res.DatosResult);
                      this.configuration.setDivisasValue(
                        infoDivisa.DatosResult.ListaDivisas
                      );
                      this.translate.setLocale(res.DatosResult.Idioma);
                      this.translate.use(res.DatosResult.Idioma);
                      this.configuration.dataReady.next(true);
                    });
                });
            });
        });
    }
  }

  ngOnInit() {
    switch (environment.chClaveEmpresa) {
      case 'REALESCUELA':
        {
          this.titleService.setTitle(
            'Ticketing TPV - Real Escuela Andaluza del Arte Ecuestre'
          );
        }
        break;

      case 'PEDRERA':
        {
          this.titleService.setTitle(
            'Ticketing TPV  - Fundació Catalunya - LaPedrera'
          );
        }
        break;

      case 'MAPFRE':
        {
          this.titleService.setTitle('Ticketing TPV - Fundación Mapfre');
        }
        break;
      case 'AYTO_ZGZ_FORO':
        {
          this.titleService.setTitle('Ticketing TPV - Museos del Ayuntamiento de Zaragoza.');
        }
        break;
      case 'FMCMP_Madrid':
        {
          this.titleService.setTitle('Ticketing TPV - Fundación María Cristina Masaveu Peterson.');
        }
        break;
    }
    this.datosTPVPathSub = this.configuration.datosTPVPath.subscribe(
      (datos) => {
        if (datos)
          this.cajaIniciada = this.configuration.datosTPVPathValue
            .CajaActivaDatos
            ? true
            : false;
      }
    );
    this.subscriptions.push(this.datosTPVPathSub);
    this.tpvEnUsoSub = this.configuration.TPVEnUso$.subscribe((EnUso) => {
      if (EnUso) {
        this.tpvEnUso = this.configuration.TPVEnUsoValue == '1' ? true : false;
      }
    });
    this.subscriptions.push(this.tpvEnUsoSub);
    this.listadoTPVsEnUsoSub = this.configuration.listadoTPVsEnUso$.subscribe(
      (listado) => {
        if (listado) {
          this.listadoTPVDisponibles = this.configuration.listadoTPVsEnUsoValue;
        }
      }
    );
    this.subscriptions.push(this.listadoTPVsEnUsoSub);
    this.tpvSeleccionadoSub = this.configuration.tpvSeleccionado$.subscribe(
      (valor) => {
        if (valor) {
          this.tpvSelecciconado =
            this.configuration.tpvSeleccionadoValue == '1' ? true : false;
        }
      }
    );
    this.subscriptions.push(this.tpvSeleccionadoSub);

    this.loginForm = this.formBuilder.group({
      usuario: [
        environment.devUser,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9]*$'),
        ]),
      ],
      importe: [
        this.importeInicial,
        Validators.compose([
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{0,2})?'),
        ]),
      ],
      contrasena: [environment.devPassword, Validators.required],
      tpvs: '',
    });
    this.loginService.setImporteInicial(this.importeInicial);
    this.dataReadySub = this.configuration.dataReady.subscribe(
      (data: boolean) => (this.dataReady = data)
    );
    this.subscriptions.push();
    this.loginSub = this.loginService.loging$.subscribe(
      (x) => (this.loging = this.loginService.logingValue)
    );
    this.subscriptions.push(this.loginSub);
    this.cambioInicialSub = this.loginService.importeInicial$.subscribe(
      (x) => (this.importeInicial = this.loginService.importeInicialValue)
    );
    this.subscriptions.push(this.cambioInicialSub);
    this.onChanges();
  }

  getAppConfig(id) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));

    const body = {
      ConexionIacpos: conexionId,
      CodigoApp: this.config.getConfig('CODIGO_APLICACION'),
      CodigoModulo: '',
      Id: id ? id : this.config.getConfig('ID_APLICACION'),
    };
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/Configuracion/GetAppConfig`,
        body
      )
      .pipe(
        map((res: any) => {
          this.appConfig.next(res);
          this.filtrarPorCliente = res.DatosResult.AppConfigJsonConfig && res.DatosResult.AppConfigJsonConfig.ClienteFiltrarProductosPorCliente?
            res.DatosResult.AppConfigJsonConfig.ClienteFiltrarProductosPorCliente : null;
          this.maxNumImpresiones = res.DatosResult.AppConfigJsonConfig && res.DatosResult.AppConfigJsonVentaGestionar.ReimprimiticketMax?
            res.DatosResult.AppConfigJsonVentaGestionar.ReimprimiticketMax: null;
          this.setDeviceConfiguration(res.DatosResult);
          return res;
        })
      );
  }
  setDeviceConfiguration(conf: DeviceConfiguration) {
    this.deviceConfigurationSubject.next(conf);
  }
  onChanges() {
    this.loginFormSub = this.loginForm.valueChanges.subscribe((val) => {
      if (val) {
        this.loginService.setImporteInicial(val.importe || '0');
      }
    });
  }

  ngOnDestroy() {
    this.formControlService.unfocus();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    if (this.loginFormSub) this.loginFormSub.unsubscribe();
  }

  login() {
    this.disabledEntrar = true;
    /*if (this.loginForm.invalid || this.loging || !this.dataReady) {
      this.dataReady = false;
      this.disabledEntrar = false;
      return;
    }*/
    //comprobamos si el tpv esta en uso antes de hacer el login o cargar los disponibles si no lo esta
    this.configuration.getTPVPathLocal();
    if (!this.configuration.tpvFisicoValue) {
      this.configuration
        .getTPVEnUso(this.configuration.tpvPathLocalValue)
        .subscribe((TPV: any) => {
          if (TPV.Estado == '1') {
            let config = this.configuration.getTPVPathOrigenValue();
            this.configuration.setTpvPathLocal(config);
            this.disabledEntrar = false;
            return;
          } else {
            this.realizarLogin();
          }
        });
    } else {
      this.realizarLogin();
    }
  }

  realizarLogin() {
    this.loginService.setVentanaOrigen('login');
    this.loginService.setUsuario(this.loginForm.controls['usuario'].value);
    this.loginService.setContrasena(
      this.loginForm.controls['contrasena'].value
    );
    this.loginService.setImporteInicial(this.importeInicial);
    this.loginService
      .comprobarUsuario(
        this.loginForm.controls['usuario'].value,
        this.loginForm.controls['contrasena'].value
      )
      .subscribe((res) => {
        if (res) {
          let mensaje;
          let titulo = this.translate.instant('CREDENCIALES_INVALIDAS');
          if (res.Mensajes.length > 0) {
            this.disabledEntrar = false;
            this.loginService.setLoging(false);
            switch (res.Mensajes[0].CodigoMensaje) {
              case '-6':
                this.loginService.setUsuarioId(res.DatosResult.Id);
                this.loginService.setCambiarPass(true);
                this.router.navigate(['cambiar-password']);
                this.mensajeError(
                  this.translate.instant('NECESARIOCAMBIARPASS'),
                  this.translate.instant('PASS_CADUCADA')
                );
                break;
              case '10':
                this.loginService.setPedirCodigoAuth(true);
                this.router.navigate(['codigoAuth']);
                this.toastr.info(
                  this.translate.instant('NECESARIOCODIGOCORRREO'),
                  this.translate.instant('CODIGO_AUTENTICACION'),
                  {
                    toastComponent: InfoToast,
                    timeOut: 5000,
                  }
                );
                break;
              case '-5':
                this.mensajeError(
                  this.translate.instant('USUARIOBLOQUEADO'),
                  titulo,
                  10000
                );
                break;
              case '-12':
                this.mensajeError(
                  this.translate.instant('USUARIOINCORRECTO'),
                  titulo,
                  10000
                );
                break;
              default:
                this.mensajeError(
                  this.translate.instant('USUARIOINCORRECTO'),
                  titulo,
                  10000
                );
                break;
            }
            let config = this.configuration.getTPVPathOrigenValue();
            this.configuration.setTpvPathLocal(config);
            if (!this.configuration.tpvFisicoValue) {
              this.configuration.getTPVEnUso(config).subscribe();
            }
          }
        } else {
          if (
            !this.cajaIniciada &&
            this.configuration.appConfig.value.DatosResult.AppConfigJsonTurno &&
            this.configuration.appConfig.value.DatosResult.AppConfigJsonTurno
              .TurnoInicioDesgloseMoneda == '1'
          ) {
            this.desgloseService.setEsperarDesglose(true);
            this.desgloseService.setVentanaOrigen('login');
            this.modalService.open('desglosarCambio');
          }
          this.esperarSub = this.desgloseService.esperaDesglose$.subscribe(
            (esperar) => {
              if (!esperar) {
                this.loginService
                  .cargarDatosLogin(
                    this.loginService.usuarioValue,
                    this.loginService.contrasenaValue,
                    this.loginService.importeInicialValue
                  )
                  .subscribe((res) => {
                    this.disabledEntrar = false;
                    if (this.config.getConfig('COMPRAINICIO')) {
                      this.router.navigate([
                        this.config.getConfig('COMPRAINICIO'),
                      ]);
                    } else {
                      this.router.navigate(['']);
                    }
                    this.desgloseService.inicializarDesglose();
                    this.esperarSub.unsubscribe();
                  });
              }
            }
          );
        }
      });
  }

  focusValue(event: any) {
    this.formControlService.setCurrentFormGroup(
      this.loginForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  deleteValue() {
    this.formControlService.setToBlank();
  }

  isSelected(element) {
    return this.formControlService.currentFormGroupValue &&
      this.formControlService.currentFormGroupValue.elemento &&
      element.id === this.formControlService.currentFormGroupValue.elemento.id
      ? true
      : false;
  }

  cancelar() {
    if (
      this.window.external &&
      typeof this.window.external.salir === 'function'
    ) {
      window.close();
    }
  }

  estaIniciada() {
    return (
      this.cajaIniciada ||
      (this.configuration.appConfig.value &&
        this.configuration.appConfig.value.DatosResult &&
        this.configuration.appConfig.value.DatosResult.AppConfigJsonTurno &&
        this.configuration.appConfig.value.DatosResult.AppConfigJsonTurno
          .TurnoInicioDesgloseMoneda == '1')
    );
  }

  disableButtonEnter() {
    return this.disabledEntrar;
  }

  verSelector() {
    return this.tpvSelecciconado || this.tpvEnUso;
  }
  selectTPV(cambio) {
    if (cambio.value !== '') {
      this.configuration.setTPVSeleccionadoValue('1');
      this.configuration.setTpvPathLocal(cambio.value);
      localStorage.setItem('currentPath', JSON.stringify(cambio.value));
      this.loginService
        .actualizarDatosTPVPath(cambio.value)
        .subscribe((res) => {
          this.configuration.setTPVEnUsoValue('0');
        });
    }
    return cambio.value;
  }

  mensajeError(mensaje: string, titulo: string, tiempo = 5000) {
    this.toastr.error(mensaje, titulo, {
      toastComponent: ErrorToast,
      timeOut: tiempo,
    });
  }
}
