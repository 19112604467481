import { Printer } from '../printer.interface';
import * as jspdf from 'jspdf';
import { PrintableType } from '../printableType.enum';
import { OsConnectionFactory } from '../../connection/osConnection.factory';
import { ConnectionType } from '../../connection/connectionType.enum';
import * as QRCode from 'qrcode';
import { JointTicketOptions } from '../printOptions';
import * as moment from 'moment';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { AppConfiguration } from 'src/app/app.configuration';
import { ConfigurationService } from 'src/app/core/conf';

export class JointTicket implements Printer {
  private documentBody;
  type: PrintableType;
  private altura; // altura del papel
  private anchura; //anchura del papel
  private variacion; // TODO comprobarlo actualmente se multiplica altura por anchura por 2,83
  private alturaEfectiva; // altura disponible para la escritura
  private inicialx = 0; // posicion inicial en el eje x
  private inicialy = 0; // posicion inicial en el eje  y
  private rotacion; //rotacion del papel (angulo de impresion)
  private orientacion; // orientacion del papel valores: 'p' = o 'l' (landscape or portrait)
  private unidad; // unidad de medida cuando se especifican las coordenadas  "pt" (points), "mm" (Default), "cm", "in"
  private format; // se pueden enviar la medida o usar los siguientes formatos 'a3', 'a4' (Default),'a5' ,'letter' ,'legal'
  private y; // posicion actual en el eje Y
  private x; //posicion actual en el eje X
  private width; //anchura real del documento
  private height; //altura real del documento
  private tipoLetra; // 
  private empresa;

  // Yaribel 20210122  Comentamos y modificamos el constructor y el metodo paint para imprimir el ticket de la pedrera
  constructor(private translate: TranslateService, private config: AppConfiguration, private configuration: ConfigurationService) {
    this.type = PrintableType.TICKET;
    this.empresa = 'PEDRERA';//this.config.getConfig('chClaveEmpresa');

    switch (this.empresa) {
      case 'PEDRERA':
        this.altura = 60;
        this.anchura = 152;
        this.unidad = 'mm';
        this.orientacion = 'p';
        this.inicialy = 5;
        this.inicialx = 97;
        this.variacion = 2.83;
        break;
      case 'SNOWZONE':
        this.altura = 24;
        this.anchura = 252;
        this.unidad = 'mm';
        this.orientacion = 'p';
        this.inicialy = 7;
        this.inicialx = 0;
        this.variacion = 2.83;
        break;
      default:

        break;
    }

    const opt = {
      orientation: this.orientacion,
      unit: this.unidad,
      format: [this.anchura * this.variacion, this.altura * this.variacion]
    };
    this.documentBody = new jspdf(opt);

    //this.documentBody = new jspdf('p', 'mm', 'a4');
  }

  paint(options: JointTicketOptions): Printer {

    // const fecha = moment(options.entrada.Fecha).format('DD/MM/YYYY');
    // const hora = options.entradaDate.slice(0, -3);
    // this.documentBody.setFontSize(8);

    const width = this.documentBody.internal.pageSize.getWidth();
    const height = this.documentBody.internal.pageSize.getHeight();
    this.y = this.inicialy;
    this.x = this.inicialx;
    this.documentBody.setFontSize(8);
    if (options.qrCode !== '') {
      switch (this.empresa) {
        case 'PEDRERA':
          let nombreRecinto;
          let fecha;
          let hora;
          let motivoGrupo;
          let precio;
          // if (element.InfoTickets.recintosImprimir && element.InfoTickets.recintosImprimir.length > 0 &&
          //   element.InfoTickets.recintosImprimir[numelemento>element.InfoTickets.recintosImprimir.length -1?0:numelemento] != undefined) {
          //     fecha = moment(element.InfoTickets.recintosImprimir[numelemento>element.InfoTickets.recintosImprimir.length -1?0:numelemento].FechaHoraEntrada).format('DD/MM/YYYY');
          //     hora = element.InfoTickets.recintosImprimir[numelemento>element.InfoTickets.recintosImprimir.length -1?0:numelemento].FechaHoraEntrada.slice(11, -3);
          //     nombreRecinto =  element.InfoTickets.recintosImprimir[numelemento>element.InfoTickets.recintosImprimir.length -1?0:numelemento].NombreRecinto
          // }
          // precio = entradas.find((x) => x.ItemId == element.InfoTickets.TipoEntradaId)? entradas.find((x) => x.ItemId == element.InfoTickets.TipoEntradaId).PrecioTotal: '';
          // precio = options.entrada.PrecioEnDivisaReferencia ? options.entrada.PrecioEnDivisaReferencia: options.entrada.PrecioTotal;
          precio = this.addDotsToNumber(Number.parseFloat(options.entrada.PrecioTotal.replace(',', '.')).toFixed(2).replace('.', ',')
          );
          this.documentBody.text(options.entrada.NombreImpresion, this.y += 4, this.x, { angle: 90 });
          if (options.motivos) {
            // motivoGrupo = motivos.find((x) => x.NumTicket ==  element.NumOrden)? 
            //               motivos.find((x) => x.NumTicket ==  element.NumOrden).ItemNombre: '';
            this.documentBody.text(options.motivos[0].ItemNombre, this.y += 4, this.x, { angle: 90 });
            this.documentBody.text(`${this.translate.instant('PRECIO')}: ` + precio, this.y, this.x - 30, { angle: 90 });

          } else {
            this.documentBody.text(`${this.translate.instant('PRECIO')}: ` + precio, this.y += 4, this.x - 20, { angle: 90 });
          }
          if (options.recinto) {
            if (options.entrada.TipoProducto == 2) {
              options.entrada.PromocionEntradas.forEach((element, index) => {
                if (element.NumOrdenPromo == options.entrada.NumOrdenPromos[0]) {
                  const recinto = options.recinto.find(x => x.ItemId == element.TipoEntradaId);
                  if (recinto) {
                    const hora = moment(recinto.FechaEntrada, 'YYYY/MM/DD HH:mm:ss').format('HH:mm');
                    const nombreRecinto = recinto.RecintoNombre;
                    const fecha = moment(recinto.FechaEntrada, 'YYYY/MM/DD HH:mm:ss').format('DD/MM/YYYY');
                    if (nombreRecinto) {
                      this.documentBody.text(nombreRecinto + ' ' + fecha + ' ' + hora, this.y += 4, this.x, { angle: 90 });
                    }
                    // if(fecha){this.documentBody.text('Data : ' + fecha, this.y+=4, this.x, { angle: 90 });}
                    // if(hora){this.documentBody.text('H. Inici: ' + hora, this.y, this.x - 30, { angle: 90 });}
                  }
                }
              });

            } else {
              const recinto = options.recinto.find(x => x.TipoEntrada == options.entrada.TipoEntradaId);
              const hora = moment(recinto.FechaEntrada, 'YYYY/MM/DD HH:mm:ss').format('HH:mm');//options.entrada.FechaEntrada + ' ' + options.entrada.HoraEntrada,
              const nombreRecinto = recinto.RecintoNombre;
              const fecha = moment(options.entrada.Fecha).format('DD/MM/YYYY');

              if (nombreRecinto) {
                this.documentBody.text(nombreRecinto, this.y += 4, this.x, { angle: 90 });
              }
              if (fecha) {
                this.documentBody.text(`${this.translate.instant('FECHA')} : ` + fecha, this.y += 4, this.x, { angle: 90 });
              }
              if (hora) {
                this.documentBody.text(`${this.translate.instant('TICKET_HORA_INICIO')} ` + hora, this.y, this.x - 30, { angle: 90 });
              }

            }
          }

          this.documentBody.text(`${this.translate.instant('JUSTIFICANTE_No_ALBARAN')}: ` + options.albaranId, this.y += 4, this.x, { angle: 90 });
          this.documentBody.text(options.tpvName + ' - ' + options.boughtAt + ' ' + options.boughtAtHour
            , this.y += 4, this.x, { angle: 90 });
          this.documentBody.text(`${this.translate.instant('TICKET_No_Pers')}: ` + options.entrada.Cantidad, this.y += 4, this.x, { angle: 90 });
          this.documentBody.text(`${this.translate.instant('JUSTIFICANTE_NIF')}: ` + options.NIFEmpresa, this.y += 4, this.x, { angle: 90 });

          QRCode.toCanvas(
            options.qrCode,
            { errorCorrectionLevel: 'M' },
            (err, canvas) => {
              if (err) {
                throw err;
              }
              const imgData = canvas.toDataURL('image/png');
              this.documentBody.addImage(imgData, 'PNG', width - 30, this.x - 60, 20, 20);
              this.documentBody.text(options.locator, width - 5, this.x - 42, { angle: 90 });
            }
          );

          break;
        case 'SNOWZONE':
        default:
          // QRCode.toCanvas(
          //   options.qrCode,
          //   { errorCorrectionLevel: 'M' },
          //   (err, canvas) => {
          //     if (err) {
          //       throw err;
          //     }
          //     const imgData = canvas.toDataURL('image/png');
          //     pdf.addImage(imgData, 'PNG', 0.4, (height / 4.5) * 1.5, width, width);
          //     pdf.text(element.InfoTickets.NombreEntrada, 7, (height / 4.5) * 1.5, { angle: 90 });
          //     pdf.text(idTpv + ' - ' + idAlbaran + ' / ' + element.NumOrden, 11, (height / 4.5) * 1.5, { angle: 90 });
          //     if (element.InfoTickets.recintosImprimir && element.InfoTickets.recintosImprimir.length > 0 &&
          //       element.InfoTickets.recintosImprimir[numelemento>element.InfoTickets.recintosImprimir.length -1?0:numelemento] != undefined) {
          //       pdf.text(element.InfoTickets.recintosImprimir[numelemento>element.InfoTickets.recintosImprimir.length -1?0:numelemento].NombreRecinto, 15, (height / 4.5) * 1.5, { angle: 90 });
          //       //pdf.text(element.InfoTickets.FechaEntrada + ' ' + element.InfoTickets.HoraEntrada, 19, (height / 4.5) * 1.5, { angle: 90 });
          //       pdf.text(element.InfoTickets.recintosImprimir[numelemento>element.InfoTickets.recintosImprimir.length -1?0:numelemento].FechaHoraEntrada, 19, (height / 4.5) * 1.5, { angle: 90 });
          //       //FechaHoraEntrada
          //     }
          //   }
          // );

          let y = 0; // = 10;
          y += 8;
          QRCode.toCanvas(options.qrCode, { errorCorrectionLevel: 'H' }, (err, canvas) => {
            if (err) {
              throw err;
            }
            const imgData = canvas.toDataURL('image/png');
            this.documentBody.addImage(imgData, 'PNG', 72, (y -= 2), 20, 20);
          });

          this.documentBody.text(
            `${options.index}/${options.totalEntradasConjuntas}`,
            90,
            y + 21,
            'right'
          );
          this.documentBody.text(options.tpvName, 90, y + 24, 'right');
          this.documentBody.text(options.albaranId, 90, y + 27, 'right');

          this.documentBody.text('', 11, y, 'left');
          this.documentBody.text(`${options.locator} - ${options.index}`, 90, y, 'right');
          this.documentBody.text('200', 11, (y += 3), 'left');

          if (options.recinto) {
            this.documentBody.setFontSize(14);
            this.documentBody.text(
              moment(options.recinto[0].FechaEntrada, 'YYYY/MM/DD HH:mm:ss').format('HH:mm'),
              //options.entrada.FechaEntrada + ' ' + options.entrada.HoraEntrada,
              11,
              y + 14,
              'left'
            );
            this.documentBody.text(
              options.recinto[0].RecintoNombre.substring(0, 22),
              11,
              (y += 8),
              'left'
            );
            this.documentBody.setFontSize(8);
          }
          if (options.motivos) {
            this.documentBody.text(
              `${options.entrada.NombreImpresion} / ${options.motivos[0].ItemNombre}`,
              11,
              (y += 27),
              'left'
            );
          } else {
            this.documentBody.text(`${options.entrada.NombreImpresion}`, 11, (y += 27), 'left');
          }
          this.documentBody.text(
            `${options.boughtAt} / ${options.boughtAtHour} ${this.translate.instant('TICKET_HORA_COMPRA')}
        `,
            11,
            (y += 4),
            'left'
          );
          this.documentBody.text(
            `${'PVP:'} ${this.addDotsToNumber(options.entrada.PrecioEnDivisaReferencia)} €`,
            90,
            y,
            'right'
          );
          if (options.paseHorario) {
            this.documentBody.text(`${fecha} / ${hora} ${this.translate.instant('TICKET_HORA_VISITA')}`, 11, (y += 4), 'left');
          } else {
            this.documentBody.text(
              `${fecha} / ${this.translate.instant('SIN_SELECCION_PASE_HORARIO')} ${this.translate.instant('VISITA')}`,
              11,
              (y += 4),
              'left'
            );
          }
          break;
      }

    }


    // this.documentBody.text(`${'FILA'} / ${'BUTACA'}`, 80, y, 'right');
    return this;
  }


  // constructor() {
  //   this.type = PrintableType.TICKET;
  //   this.documentBody = new jspdf('p', 'mm', 'a4');
  // }

  // paint(options: JointTicketOptions): Printer {
  //   const fecha = moment(options.entrada.Fecha).format('DD/MM/YYYY');
  //   const hora = options.entradaDate.slice(0, -3);
  //   let y = 0; // = 10;
  //   this.documentBody.setFontSize(8);
  //   y += 8;
  //   if (options.qrCode !== '') {
  //     QRCode.toCanvas(options.qrCode, { errorCorrectionLevel: 'H' }, (err, canvas) => {
  //       if (err) {
  //         throw err;
  //       }
  //       const imgData = canvas.toDataURL('image/png');
  //       this.documentBody.addImage(imgData, 'PNG', 72, (y -= 2), 20, 20);
  //     });
  //   }
  //   this.documentBody.text(
  //     `${options.index}/${options.totalEntradasConjuntas}`,
  //     90,
  //     y + 21,
  //     'right'
  //   );
  //   this.documentBody.text(options.tpvName, 90, y + 24, 'right');
  //   this.documentBody.text(options.albaranId, 90, y + 27, 'right');

  //   this.documentBody.text('', 11, y, 'left');
  //   this.documentBody.text(`${options.locator} - ${options.index}`, 90, y, 'right');
  //   this.documentBody.text('200', 11, (y += 3), 'left');

  //   if (options.recinto) {
  //     this.documentBody.setFontSize(14);
  //     this.documentBody.text(
  //       moment(options.recinto[0].FechaEntrada, 'YYYY/MM/DD HH:mm:ss').format('HH:mm'),
  //       //options.entrada.FechaEntrada + ' ' + options.entrada.HoraEntrada,
  //       11,
  //       y + 14,
  //       'left'
  //     );
  //     this.documentBody.text(
  //       options.recinto[0].RecintoNombre.substring(0, 22),
  //       11,
  //       (y += 8),
  //       'left'
  //     );
  //     this.documentBody.setFontSize(8);
  //   }
  //   if (options.motivos) {
  //     this.documentBody.text(
  //       `${options.entrada.NombreImpresion} / ${options.motivos[0].ItemNombre}`,
  //       11,
  //       (y += 27),
  //       'left'
  //     );
  //   } else {
  //     this.documentBody.text(`${options.entrada.NombreImpresion}`, 11, (y += 27), 'left');
  //   }
  //   this.documentBody.text(
  //     `${options.boughtAt} / ${options.boughtAtHour} h COMPRA
  //   `,
  //     11,
  //     (y += 4),
  //     'left'
  //   );
  //   this.documentBody.text(
  //     `${'PVP:'} ${this.addDotsToNumber(options.entrada.PrecioEnDivisaReferencia)} €`,
  //     90,
  //     y,
  //     'right'
  //   );
  //   if (options.paseHorario) {
  //     this.documentBody.text(`${fecha} / ${hora} h VISITA`, 11, (y += 4), 'left');
  //   } else {
  //     this.documentBody.text(
  //       `${fecha} / ${'SIN SELECCION DE PASE HORARIO'} VISITA`,
  //       11,
  //       (y += 4),
  //       'left'
  //     );
  //   }
  //   // this.documentBody.text(`${'FILA'} / ${'BUTACA'}`, 80, y, 'right');
  //   return this;
  // }

  print(connectionType = 'VIRTUAL') {
    let conn = ConnectionType.VIRTUAL;
    if (connectionType === 'VIRTUAL') {
      conn = ConnectionType.VIRTUAL;
    }
    if (connectionType === 'ASPNET') {
      conn = ConnectionType.ASPNET;
    }
    const connection = OsConnectionFactory.getConnection(conn);
    connection.print(this.documentBody, this.type);
  }

  addDotsToNumber(number, decimalLength = 2) {
    number = '' + number;
    number.replace('.', ',');
    if (number.includes(',')) {
      // tslint:disable-next-line:prefer-const
      let [integer, decimal] = number.split(',');
      if (integer.length > 3) {
        const integerArray = integer.split('');
        let newInteger = '';
        integerArray.forEach((num, index) => {
          if ((integerArray.length - index) % 3 === 0) {
            newInteger += '.';
          }
          newInteger += num;
        });
        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        return newInteger;
      } else {
        let newInteger = integer;

        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        return newInteger;
      }
    } else if (number.length > 3) {
      const integerArray = number.split('');
      let newInteger = '';
      integerArray.forEach((num, index) => {
        if ((integerArray.length - index) % 3 === 0) {
          newInteger += '.';
        }
        newInteger += num;
      });
      let decimal = ',';
      while (decimal.length <= decimalLength) {
        decimal += '0';
      }
      newInteger += decimal;
      return newInteger;
    }
    let d = ',';
    for (let i = 0; i < decimalLength; ++i) {
      d += '0';
    }
    return number + d;
  }
}
