import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../../../shared/services/translate.service';

@Pipe({
  name: 'formaPago'
})
export class FormaPagoPipe implements PipeTransform {

  constructor(private translate: TranslateService) {}
  transform(value: any, args?: any): any {
    switch (value) {
      case 'IN':
        return this.translate.instant('INTERNET');
      case 'CC':
        return this.translate.instant('TARJETA_CREDITO');
      case 'CA':
        return this.translate.instant('EFECTIVO');
      case 'TR':
        return this.translate.instant('TRANSFERENCIA');
      case 'TM':
        return this.translate.instant('TARJETA_MONEDERO'); // Yaribel 12112020 Añadimos la forma de pago  TM para que visualice el nombre de la forma de pago en gestionar venta
       case 'DP':
        return this.translate.instant('APLAZADO');
       case 'PR':
         return this.translate.instant('PREPAGO'); // Yaribel 20201217 añadimos forma de pago Prepago
      default:
        return '';
    }
  }

}
