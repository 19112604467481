import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormControlService } from '../../services/form-control.service';
import { VentaService } from 'src/app/modules/venta';
import { FacturaService } from '../../services/factura.service';
import { concatMap, first, take, takeLast, takeWhile } from 'rxjs/operators';
import { PrintService } from '../../services/print.service';
import { PagerService } from '../../services/pager.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { SuccessToast } from 'src/app/core/toast/success.toast';
import { pais} from 'src/app/modules/venta/models/procedencias.model';
import { AlbaranService } from 'src/app/modules/gestionar-venta/services/albaran.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '../../services/translate.service';

@Component({
  selector: 'ticketing-facturacion',
  templateUrl: './facturacion.component.html',
  styleUrls: ['./facturacion.component.scss']
})
export class FacturacionComponent implements OnInit {
  @Input() id: string;

  private element: any;
  facturacionForm: FormGroup;
  abrir: boolean;
  clientes = [];
  pager: any = {};
  pagedItems: any[];
  listadoPaises: any[];
  private alive = true;
  estaFacturando = false;
  buscarClientesSub:Subscription;
  factuaracionFormSub: Subscription;
  getPaisesSub: Subscription;

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private formControlService: FormControlService,
    private el: ElementRef,
    private venta: VentaService,
    private facturaService: FacturaService,
    private printService: PrintService,
    private pagerService: PagerService,
    private toastr: ToastrService,
    private albaranService: AlbaranService,
    private translate: TranslateService
  ) {
    this.element = el.nativeElement;
    this.facturacionForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      codigoLegal: ['', Validators.required],
      direccion: [''],
      ciudad: [''],
      codigoPostal: [''],
      pais: [''],
      provincia: [''],
      privacidad: ['',Validators.requiredTrue],
      codigoCliente: [''],
      comentarios: [''],
      search_field: ['']
    });
    // this.facturaService
    //   .buscarCliente(this.facturacionForm.valueChanges)
    //   .subscribe((res: any) => {
    //     //if (res.DatosResult) {
    //     //this.clientes = res.DatosResult.ListadoContacto;
    //       if(res.ResultadoBuscadorClientes){
    //       this.clientes = res.ResultadoBuscadorClientes;
    //       this.setPage(1);
    //     } else {
    //       this.clientes = [];
    //     }
    //   });
      // this.facturaService
      // .getPaises()
      // .pipe(takeWhile(() => this.alive))
      // .subscribe((res: any) => {
      //   // ;
      //   if (res.DatosPaises) {
      //     // seleccionable
      //     this.listadoPaises = res.DatosPaises;
      //     // 
      //   }
      // });
  }
  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.clientes.length, page);
    this.pagedItems = this.clientes.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );

    this.pager.pages = this.pager.pages.filter(
      num => num <= this.pager.totalPages
    );

    while (this.pager.pages.length < 6 && this.pager.pages[0] > 1) {
      this.pager.pages.unshift(this.pager.pages[0] - 1);
    }
  }

  ngOnInit() {
    const modal = this;
    if (!this.id) {
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
    this.estaFacturando= false;
  }

  open(): void {
    this.element.style.display = 'flex';
    document.body.classList.add('jw-modal-open');
    this.alive = true;
    this.initForm();
  }

  close(): void {
    this.alive = false;
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    this.abrir = false;
    this.estaFacturando = false;
    if(this.buscarClientesSub){ this.buscarClientesSub.unsubscribe();}
    if(this.getPaisesSub){this.getPaisesSub.unsubscribe();}
    this.facturacionForm.reset();
    if(this.factuaracionFormSub){this.factuaracionFormSub.unsubscribe();}
  }

  facturar() {
    if(!this.estaFacturando)
    {
    this.estaFacturando = true;
    this.facturaService
      .crearFactura(this.facturacionForm.value)
      .pipe(first())
      .subscribe(res => {
        if (res) {
          this.facturaService.impresionFactura(res.DatosResult).subscribe(
            (response) => {
              this.toastr.success('Factura realizada correctamente', '', {
                toastComponent: SuccessToast
              });   
              this.albaranService.refrescarAlbaran(this.albaranService.albaranValue.NumAlbaran).subscribe();
            },
            (error) => {
              if (error.status === 204) {
                this.toastr.error('Error', this.translate.instant('ERROR_AVISAR_ENCARGADO'), {
                  toastComponent: ErrorToast
                });
              } else if (error.status === 400) {
                this.toastr.error('Error', this.translate.instant('ERROR_AVISAR_ENCARGADO'), {
                  toastComponent: ErrorToast
                });
              } else if (error.status === 401) {
                this.toastr.error('Error', this.translate.instant('ERROR_AVISAR_ENCARGADO'), {
                  toastComponent: ErrorToast
                });
              } else if (error.status === 404) {
                this.toastr.error('Error', this.translate.instant('ERROR_AVISAR_ENCARGADO'), {
                  toastComponent: ErrorToast
                });
              } else if (error.status === 500) {
                this.toastr.error('Error', this.translate.instant('ERROR_AVISAR_ENCARGADO'), {
                  toastComponent: ErrorToast
                });
              } else {
                this.toastr.error('Error', this.translate.instant('ERROR_AVISAR_ENCARGADO'), {
                  toastComponent: ErrorToast
                });
             }
            }
          );
         

          this.close();
          /*if (res.DatosResult) {
            this.printService.generateFacturaPDF(res.DatosResult.FacturaRef[0]);*/
        } else {
          this.toastr.error('Error', this.translate.instant('ERROR_AVISAR_ENCARGADO'), {
            toastComponent: ErrorToast
          });
          this.estaFacturando = false;
          return;
        }
      });
    }
    // setTimeout(() => {
    //   this.close();
    // }, 8000);
  }

  focusValue(event: any) {
    this.formControlService.setCurrentFormGroup(
      this.facturacionForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }
  
  seleccionarCliente(cliente) {
    
    this.facturaService.setClienteSeleccionadoValue(cliente);
    this.abrirBusqueda(false);
    this.facturacionForm.controls['nombre'].patchValue(cliente.NombreClienteFiscal);
    this.facturacionForm.controls['codigoLegal'].patchValue(cliente.CIFoDNIFiscal);
    this.facturacionForm.controls['direccion'].patchValue(cliente.DomicilioFiscal);
    this.facturacionForm.controls['ciudad'].patchValue(cliente.LocalidadFiscal);
    this.facturacionForm.controls['codigoPostal'].patchValue(cliente.CPFiscal);
    // this.facturacionForm.controls['pais'].patchValue(cliente.PaisFiscal);
    this.facturacionForm.controls['pais'].patchValue(this.getNombrePais(cliente.PaisFiscal));
    this.facturacionForm.controls['provincia'].patchValue(cliente.ProvinciaFiscal);
    this.facturacionForm.controls['codigoCliente'].patchValue(cliente.Codigo);
    // this.abrir = false;

  }
  abrirBusqueda(valor){
    
    

    if(valor){
      this.facturacionForm.get('search_field').setValue(this.facturacionForm.controls['nombre'].value);
    }else{
      this.facturacionForm.controls['search_field'].patchValue('');
    }
    this.abrir = valor;
    
    

  }

  // initForm() {
  //   if (this.venta.clienteSeleccionadoValue) {
  //     const cliente = this.venta.clienteSeleccionadoValue;
  //     this.facturacionForm.controls['nombre'].patchValue(cliente.NombreCliente);
  //     this.facturacionForm.controls['codigoLegal'].patchValue(cliente.CIFoDNI);
  //     this.facturacionForm.controls['direccion'].patchValue(cliente.Domicilio);
  //     this.facturacionForm.controls['ciudad'].patchValue(cliente.Localidad);
  //     this.facturacionForm.controls['codigoPostal'].patchValue(cliente.CP);
  //     this.facturacionForm.controls['pais'].patchValue(cliente.Pais);
  //     this.facturacionForm.controls['provincia'].patchValue(cliente.Provincia);
  //   }
  // }
  initForm() {
    this.getPaisesSub = this.facturaService
      .getPaises()
      .pipe(takeWhile(() => this.alive))
      .subscribe((res: any) => {
        // ;
        if (res.DatosPaises) {
          // seleccionable
          this.listadoPaises = res.DatosPaises;
          // 
        }
      });

    if (this.venta.clienteSeleccionadoValue) {
      let cliente;

      this.facturaService
      .buscarClienteXCodigo(this.venta.clienteSeleccionadoValue.Codigo)
      .pipe(take(1))
      .subscribe((res: any) => {;
          if(res.ResultadoBuscadorClientes){
            if(res.ResultadoBuscadorClientes.length == 1)
            {
              if(res.ResultadoBuscadorClientes[0].Codigo > 0){
              cliente = res.ResultadoBuscadorClientes[0];
              this.facturacionForm.controls['nombre'].patchValue(cliente.NombreClienteFiscal);
              this.facturacionForm.controls['codigoLegal'].patchValue(cliente.CIFoDNIFiscal);
              this.facturacionForm.controls['direccion'].patchValue(cliente.DomicilioFiscal);
              this.facturacionForm.controls['ciudad'].patchValue(cliente.LocalidadFiscal);
              this.facturacionForm.controls['codigoPostal'].patchValue(cliente.CPFiscal);
              this.facturacionForm.controls['provincia'].patchValue(cliente.ProvinciaFiscal);
              this.facturacionForm.controls['codigoCliente'].patchValue(cliente.Codigo);
              this.facturacionForm.controls['pais'].patchValue(this.getNombrePais(cliente.PaisFiscal));
              this.clientes = res.ResultadoBuscadorClientes;
              this.setPage(1);
              } else{
                this.clientes = [];
              }
            }

        } else {
          cliente = null;
        }
      });
    }
    this.onChanges();
  }

  onChanges() {
  this.factuaracionFormSub = this.facturacionForm.valueChanges.subscribe(val =>{
    if(val.search_field && val.search_field.length > 0)
    {
      this.buscarClientesSub = this.facturaService
    .buscarCliente(this.facturacionForm.valueChanges)
    .subscribe((res: any) => {
        if(res.ResultadoBuscadorClientes){
        this.clientes = res.ResultadoBuscadorClientes;
        this.setPage(1);
      } else {
        this.clientes = [];
      }
    });
    }
  });
   
  }

  ngOnDestroy() {
    this.alive = false;
    if(this.buscarClientesSub){ this.buscarClientesSub.unsubscribe();}
    if(this.getPaisesSub){this.getPaisesSub.unsubscribe();}
    if(this.factuaracionFormSub){this.factuaracionFormSub.unsubscribe();}
  }
  
  getNombrePais (codigo){
    let NombrePais = codigo;
    let pais: pais;
    if(codigo && codigo != ''){
      pais = this.listadoPaises.find(x=> x.CodPais === codigo);
      if(pais && pais.NombrePais)
      NombrePais = pais.NombrePais;
    }
    else{
      NombrePais = '';
    }
    return NombrePais;
  }

  abrirFormularioBusqueda(){
    return this.abrir;
  }

}
