import { AppConfiguration } from 'src/app/app.configuration';
import { ConfigurationService } from 'src/app/core/conf';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { OnlineSourceAlbaranOptions } from '../printOptions';
import { PrintableType } from '../printableType.enum';
import { Printer } from '../printer.interface';
import {
  AlbaranDocumentBody,
  AlbaranOperationType
} from './albaranDocumentBody';

export class OnlineSourceAlbaran implements Printer {
  private documentBody: any;
  private type: PrintableType;

  window = window as any;
  constructor(private translate: TranslateService,
    private config: AppConfiguration, private configuration: ConfigurationService) {
    this.type = PrintableType.ALBARAN;
    this.documentBody = new AlbaranDocumentBody(this.translate, this.config,this.configuration).build();
  }

  paint(options: OnlineSourceAlbaranOptions): Printer {
    const albaran = options.albaran.datos || options.albaran;
    const cabecera = options.cabecera; //Yaribel 20201222 Añadimos cabecera para imprimir los datos de la cabecera en los justificantes con localizador
    this.documentBody = new AlbaranDocumentBody(this.translate, this.config,this.configuration)
     // .addClientHeader()
      .addClientHeader(cabecera) //Yaribel 20201222 Añadimos cabecera para imprimir los datos de la cabecera en los justificantes con localizador
      .addLine()
      .addOperation(AlbaranOperationType.VENTA)
      .addSellingPointName(albaran.TPVId, albaran.TPVNombre)
      .addAlbaranNumber(albaran.NumAlbaran)
      .addLocator(albaran.NumVenta)
      .addOnlineSourceLocator(albaran.Localizador)
      .addDateOfVisit(
        albaran.FechaHoraVenta.split(' ')[0],
        albaran.FechaHoraVenta.split(' ')[1]
      )
      .addDateOfSale(
        albaran.FechaHoraEntrada.split(' ')[0],
        albaran.FechaHoraEntrada.split(' ')[1]
      )
      .addItems(albaran.AlbaranItems)
      .addLine()
      .addDesgloseIVA(albaran.AlbaranItems)
      .addLine()
      .addTotal(albaran.TotalPagar)
      .addPaymentMethod(
        albaran.AlbaranFOPs,
        this.addDotsToNumber(albaran.CambioRecibido),
        albaran
      )
      .addChange(albaran.CambioDevuelto)
      .addLine()
      .addFooterMessage(cabecera)
      .build();
    return this;
  }

  /*   print(connectionType = 'VIRTUAL') {
    let conn = ConnectionType.VIRTUAL;
    if (connectionType === 'VIRTUAL') {
      conn = ConnectionType.VIRTUAL;
    }
    if (connectionType === 'ASPNET') {
      conn = ConnectionType.ASPNET;
    }
    const connection = OsConnectionFactory.getConnection(conn);
    connection.print(this.documentBody, this.type);
  } */

  print(connectionType = 'VIRTUAL') {
    if (
      this.window.external &&
      typeof this.window.external.imprimirPDFBinario === 'function'
    ) {
      this.window.external.imprimirPDFBinario(
        this.generatePDFBinario(this.documentBody),
        'justificante'
      );
    }
    this.documentBody.save(`${this.translate.instant('justificante')}.pdf`);
  }

  private generatePDFBinario(pdf) {
    return this.arrayBufferToBase64(pdf.output('arraybuffer'));
  }

  private arrayBufferToBase64(buffer: Iterable<number>) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  addDotsToNumber(number, decimalLength = 2) {
    number = '' + number;
    number.replace('.', ',');
    if (number.includes(',')) {
      // tslint:disable-next-line:prefer-const
      let [integer, decimal] = number.split(',');
      if (integer.length > 3) {
        const integerArray = integer.split('');
        let newInteger = '';
        integerArray.forEach((num, index) => {
          if ((integerArray.length - index) % 3 === 0) {
            newInteger += '.';
          }
          newInteger += num;
        });
        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        return newInteger;
      } else {
        let newInteger = integer;

        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        return newInteger;
      }
    } else if (number.length > 3) {
      const integerArray = number.split('');
      let newInteger = '';
      integerArray.forEach((num, index) => {
        if ((integerArray.length - index) % 3 === 0) {
          newInteger += '.';
        }
        newInteger += num;
      });
      let decimal = ',';
      while (decimal.length <= decimalLength) {
        decimal += '0';
      }
      newInteger += decimal;
      return newInteger;
    }
    let d = ',';
    for (let i = 0; i < decimalLength; ++i) {
      d += '0';
    }
    return number + d;
  }
}
