import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ElementRef,
  Inject,
  LOCALE_ID,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import { ModalService } from '../../services/modal.service';
import * as moment from 'moment';
import 'moment/min/locales';
import * as _ from 'lodash';
import { VentaService } from 'src/app/modules/venta/services/venta.service';
import { AlbaranService } from 'src/app/modules/gestionar-venta/services/albaran.service';
import { TokenManagerService, ConfigurationService } from 'src/app/core';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { Router } from '@angular/router';
import { StepperNavigationService, Carrito } from 'src/app/modules/venta';
import { SidebarNavigationService } from '../../services/sidebar-navigation.service';
import { first } from 'rxjs/operators';

interface CalendarDate {
  mDate: moment.Moment;
  selected?: boolean;
  today?: boolean;
  disabled?: boolean;
}

@Component({
  selector: 'ticketing-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() id: string;

  private element: any;
  moment = moment.locale(this.localeId);
  currentDate = moment();
  monthsFirstColumn = moment.months().splice(0, 6);
  monthsSecondColumn = moment.months().splice(6, 11);
  dayNames;
  weeks: CalendarDate[][] = [];
  sortedDates: CalendarDate[] = [];
  disabledDays = [];
  verCalendario = false;

  @Input() selectedDates: CalendarDate[] = [];

  constructor(
    private modalService: ModalService,
    private el: ElementRef,
    @Inject(LOCALE_ID) protected localeId,
    private cd: ChangeDetectorRef,
    private albaranService: AlbaranService,
    private ventaService: VentaService,
    private tokenService: TokenManagerService,
    private configuracionService: ConfigurationService,
    private router: Router,
    private sidebarNavigation: SidebarNavigationService,
    private stepperNavigation: StepperNavigationService
  ) {
    this.dayNames = moment.weekdays(true);
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.tokenService.tokenRecibido.subscribe(data => {
      if (data) {
        this.generateCalendar();
      }
    });
    const modal = this;
    // this.currentDate = this.ventaService.fechaSeleccionada;
    if (!this.id) {
      return;
    }

    if (this.modalService.exists(this.id)) {
      this.modalService.remove(this.id);
      document.getElementsByTagName('ticketing-calendar').item(0);
    }

    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);

    this.ventaService.verCalendario.subscribe(
      data => (this.verCalendario = data)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.generateCalendar();
    if (
      changes.selectedDates &&
      changes.selectedDates.currentValue &&
      changes.selectedDates.currentValue.length > 1
    ) {
      this.sortedDates = _.sortBy(
        changes.selectedDates.currentValue,
        (m: CalendarDate) => m.mDate.valueOf()
      );
    }
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  // open modal
  open(): void {
    this.element.style.display = 'flex';
    document.body.classList.add('jw-modal-open');
    this.generateCalendar();
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    this.ventaService.verCalendario.next(false);
  }

  prevMonth(): void {
    if (this.router.url === '/venta/horarios') {
      this.ventaService.verCalendario.next(false);
    }
    if (
      moment(this.currentDate)
        .subtract(1, 'months')
        .isBefore(moment(), 'month')
    ) {
      return;
    }
    this.currentDate = moment(this.currentDate).subtract(1, 'months');
    this.generateCalendar();
  }

  nextMonth(): void {
    // 
    if (this.router.url === '/venta/horarios') {
      this.ventaService.verCalendario.next(false);
    }
    this.currentDate = moment(this.currentDate).add(1, 'months');
    // 
    this.generateCalendar();
  }

  selectMonth(month: any): void {
    if (this.checkForDisabledMonths(month)) {
      this.ventaService.verCalendario.next(false);
      this.currentDate = moment(this.currentDate).set('month', month);
      // 
      this.generateCalendar();
    }
  }

  checkForDisabledMonths(month: any) {
    const m = moment();
    m.set('month', month);
    m.set('year', this.currentDate.year());
    m.set('day', this.currentDate.day());
    return !m.isBefore(moment(), 'month');
  }

  selectYear(year): void {
    if (year.isBefore(moment(), 'month')) {
      return;
    }
    this.ventaService.verCalendario.next(false);
    this.currentDate = year;
    this.generateCalendar();
  }

  generateCalendar(): void {
    let tempItems = [];
    // 
    if (this.ventaService.currentRecinto) {
      this.ventaService.currentRecinto.entradas.forEach(ele => {
        this.ventaService.carritoValue.entradas.forEach(element => {
          if (
            element.entrada.TipoProducto === '1' &&
            ele === element.entrada.TipoEntradaId
          ) {
            tempItems.push(`[1,${ele}]`);
          }
          if (element.entrada.TipoProducto === '2') {
            element.entrada.PromocionEntradas.forEach(item => {
              if (ele === item.TipoEntradaId) {
                tempItems.push(`[2,${element.entrada.TipoPromocionId}]`);
              }
            });
          }
        });
      });
      const uniques = [...new Set(tempItems)];
      let items = '';
      uniques.forEach(u => {
        items = items + u;
      });
      items = items.replace(/]\[/g, '],[');
      const TPVId = this.configuracionService.datosTPVPathValue.pkId;
      const recinto = this.ventaService.currentRecinto.Recinto;
      const cliente = this.ventaService.currentCliente;
      const filtrarPorCliente = this.configuracionService.filtrarPorCliente;

      const dates = this.fillDates(this.currentDate);
      const weeks: CalendarDate[][] = [];
      while (dates.length > 0) {
        weeks.push(dates.splice(0, 7));
      }
      this.weeks = weeks;

      this.ventaService
        .getCalendario(
          TPVId,
          recinto,
          this.currentDate,
          cliente,
          filtrarPorCliente,
          items
        )
        .subscribe((res: any) => {
          if (res.DatosResult == null) {
            res.DatosResult = { DiccionarioCalendarioDisponible: {} };
          }
          this.ventaService.verCalendario.next(true);
          let newDaysDisabled = [];
          const sesiones = Object.keys(
            res.DatosResult.DiccionarioCalendarioDisponible
          );
          // 
          const sesionesFormateadas = sesiones.map(item =>
            moment(item, 'YYYY/MM/DD').format('DD/MM/YYYY')
          );
          // 
          newDaysDisabled = this.generateDatesOfAMonth(this.currentDate).filter(
            item => {
              return !sesionesFormateadas.includes(item);
            }
          );
          // 
          const isSame =
            this.disabledDays.length == newDaysDisabled.length &&
            this.disabledDays.every(function(element, index) {
              return element === newDaysDisabled[index];
            });
          if (!isSame) {
            this.disabledDays = newDaysDisabled;
            this.open();
          }
          this.ventaService.setDisabledDays(this.disabledDays);
        });
    } else {
      
      this.ventaService
        .getFechasFestivas(this.currentDate)
        .subscribe((res: any) => {
          if (res.DatosResult) {
            this.disabledDays = res.DatosResult.LstDatosFestivos.map(dia => {
              if (dia.Venta === '1') {
                const diaSinHora = dia.Fecha.split(' ')[0];
                return diaSinHora;
              }
            });
            this.ventaService.setDisabledDays(this.disabledDays);
          }
          const dates = this.fillDates(this.currentDate);
          const weeks: CalendarDate[][] = [];
          while (dates.length > 0) {
            weeks.push(dates.splice(0, 7));
          }
          this.weeks = weeks;
          this.ventaService.verCalendario.next(true);
        });
    }
  }

  fillDates(currentMoment: moment.Moment): CalendarDate[] {
    const firstOfMonth = moment(currentMoment)
      .startOf('month')
      .day();
    const firstDayOfGrid = moment(currentMoment)
      .startOf('month')
      .subtract(firstOfMonth - 1, 'days');
    let start = firstDayOfGrid.date();
    start = start === 2 ? start - 7 : start;
    return _.range(start, start + 42).map(
      (date: number): CalendarDate => {
        const d = moment(firstDayOfGrid).date(date);
        return {
          today: this.isToday(d),
          selected: this.isSelected(d),
          disabled: this.isDisabled(d),
          mDate: d
        };
      }
    );
  }

  isDisabled(date: moment.Moment) {
    let exists;
    this.disabledDays.forEach(day => {
      if (date.format('DD/MM/YYYY') === day) {
        exists = true;
      }
    });
    // Hacer dinamico desde los datos inicio
    // const tiempoVentaMax = Number.parseInt(this.configuration.datosInicioValue.TiempoVentaMax, 10);
    // const tipo =  this.configuration.datosInicioValue.TiempoVentaMaxTipo[0];
    return exists || date.isAfter(moment().add(18, 'M'), 'day');
  }

  isToday(date: moment.Moment): boolean {
    return moment().isSame(moment(date), 'day');
  }

  isSelected(date: moment.Moment): boolean {
    return date.isSame(this.ventaService.fechaSeleccionadaValue, 'day');
  }

  isSelectedMonth(date: moment.Moment): boolean {
    return moment(date).isSame(this.currentDate, 'month');
  }

  selectDate(date: CalendarDate): void {
    // 
    if (date.disabled) {
      return;
    }
    if (date.mDate.isBefore() && !date.today) {
      return;
    }
    if (this.ventaService.carritoValue.horariosPrevios &&
      this.ventaService.carritoValue.horariosPrevios.length > 0) {
      //this.ventaService.carritoValue.resetearCarritoDeLocalizador(); // Yaribel 20210210 Eliminamos el reseteo cuando se cambia la fecha 
      //this.ventaService.carritoValue.resetearHorariosCarrito(); // Yaribel 20210210 Cambiamos el reseteo del localizador cuando se cambia la fecha por reseteo solo de los horarios del carrito
      this.stepperNavigation.continuarVenta.next(false);
      this.ventaService.carritoSubject.pipe(first()).subscribe((res1: Carrito) => {
        if (res1.carrito) {
          res1.setCarritoModificacionCarritoValue(res1.carrito);
          this.ventaService.carritoSubject.next(res1);
        }
      });
      this.stepperNavigation.resetearPasos();
      this.router.navigate(['']);
    }
    if (this.modalService.isOpen('etiquetas')) {
      const albaran = this.albaranService.albaranValue;
      albaran.FechaHoraEntrada = date.mDate.toString();
      this.albaranService.setAlbaranValue(albaran);
      this.close();
    } else {
      this.ventaService.setFechaSeleccionadaValue(
        moment(
          moment(date.mDate, 'YYYY/MM/DD').format('YYYY/MM/DD') +
            ' ' +
            moment().format('hh:mm:ss'),
          'YYYY/MM/DD hh:mm:ss'
        )
      );
      if (this.ventaService.carritoValue && this.ventaService.carritoValue.entradas.length > 0) {
        //Meter aqui el recalculo (lo pasamos al subscriptor)        
        this.close();
      } else {
        this.close();
        this.stepperNavigation.resetearPasos();
        //this.stepperNavigation.continuarVenta.next(false);
       // this.router.navigate(['']);
      }
    }
  }

  setearValorANull(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      // Aquí establecerás la variable a null
      this.ventaService.setFechaSeleccionadaValue(moment(),true); // Reemplaza 'miVariable' con el nombre de la variable que deseas settear a null
    }
  }

  getYears() {
    const years = [];
    for (let i = 0; i < 5; i++) {
      years.push(
        moment()
          .month(this.currentDate.month())
          .add(i, 'year')
      );
    }
    return years;
  }

  generateDatesOfAMonth(date) {
    let startDateOfMonth = moment(date).startOf('month');
    const endDateOfMonth = moment(date).endOf('month'),
      dayArray = [];

    while (startDateOfMonth <= endDateOfMonth) {
      dayArray.push(startDateOfMonth.format('DD/MM/YYYY'));
      startDateOfMonth = startDateOfMonth.add(1, 'days');
    }
    return dayArray;
  }
}
