import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfiguration } from 'src/app/app.configuration';
import { EspectaculosService } from './espectaculos.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  TokenManagerService,
  ConfigurationService,
  TurnoService
} from 'src/app/core';
import { InfoMapasService } from './info-mapas.service';
import * as d3 from 'd3';
import { mergeMap, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { ModalService } from 'src/app/shared/services/modal.service';
import { VentaService } from '../../venta';
import { butacaToEntrada } from '../components/seleccion-butacas/butacaToEntrada.mapper';
import * as moment from 'moment';
import { TranslateService } from '../../../shared/services/translate.service';

interface HashTable<T> {
  [key: string]: T;
}

@Injectable({
  providedIn: 'root'
})
export class ButacasService {
  private estadosButacasSubject: BehaviorSubject<any>;
  public estadosButacas: Observable<HashTable<any>>;

  private asientosDeshabilitadosSubject: BehaviorSubject<any>;
  public asientosDeshabilitados: Observable<any>;

  private butacaMarcadaSubject: BehaviorSubject<any>;
  public butacaMarcada: Observable<any>;

  private entradasSeleccionadasSubject: BehaviorSubject<any>;
  public entradasSeleccionadas: Observable<any>;

  butacasMap: HashTable<string> = {};
  butacas: any[];
  listaButacasDisponibles: string;
  relistaButacasDisponibles = [];
  butacasConPrecio = [];
  esNumerada = new BehaviorSubject<boolean>(true);
  butacaTipoNumerada = [];
  butacaTipoNoNumerada = [];
  disponibilidadButacaTipoNoNumerada = [];
  tarifaBloqueada = [];
  butacaSeleccionada;

  constructor(
    private http: HttpClient,
    private config: AppConfiguration,
    private espectaculoService: EspectaculosService,
    private tokenManagerService: TokenManagerService,
    private infoMapasService: InfoMapasService,
    private modalService: ModalService,
    private ventaService: VentaService,
    private toast: ToastrService,
    private configurationService: ConfigurationService,
    private turnoService: TurnoService,
    private translate: TranslateService
  ) {
    this.butacaMarcadaSubject = new BehaviorSubject(undefined);
    this.butacaMarcada = this.butacaMarcadaSubject.asObservable();

    this.estadosButacasSubject = new BehaviorSubject([]);
    this.estadosButacas = this.estadosButacasSubject.asObservable();

    this.asientosDeshabilitadosSubject = new BehaviorSubject([]);
    this.asientosDeshabilitados = this.asientosDeshabilitadosSubject.asObservable();

    this.entradasSeleccionadasSubject = new BehaviorSubject([]);
    this.entradasSeleccionadas = this.entradasSeleccionadasSubject.asObservable();
  }

  public get estadosButacasValue() {
    return this.estadosButacasSubject.value;
  }

  public setEstadosButacaValue(estados) {
    this.estadosButacasSubject.next(estados);
  }

  public get asientosDeshabilitadosValue() {
    return this.asientosDeshabilitadosSubject.value;
  }

  public setAsientosDeshablitados(asientos) {
    this.asientosDeshabilitadosSubject.next(asientos);
  }

  public get butacaMarcadaValue() {
    return this.butacaMarcadaSubject.value;
  }

  public setButacaMarcadaValue(butaca) {
    this.butacaMarcadaSubject.next(butaca);
  }

  public get entradasSeleccionadasValue() {
    return this.entradasSeleccionadasSubject.value;
  }

  public setEntradasSeleccionadasValue(entradas) {
    this.entradasSeleccionadasSubject.next(entradas);
  }

  pushButaca(butaca: any): boolean {
    if (this.comprobarSiButacaAgregada(butaca)) {
      return;
    }
    const butacas = this.entradasSeleccionadasValue;
    butacas.push(butaca);
    this.setEntradasSeleccionadasValue(butacas);
  }

  getEstadosButacas(recinto: any, nombreRecinto: any) {
    /* const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .attr('id', 'tooltip')
      .style('opacity', 0); */
    this.butacaTipoNoNumerada = [];
    this.butacaTipoNumerada = [];
    this.httpPostGetTipoButacasPorRecinto().subscribe((data: any) => {
      // 
      if (data !== undefined) {
        data.DatosResult.forEach(item => {
          if (item.Numeradas === '0') {
            const disponibilidad = {
              recinto: item.RecintoID,
              disponibilidad: item.ButacasDisponible
            };
            this.butacaTipoNoNumerada.push(item.RecintoID);
            this.disponibilidadButacaTipoNoNumerada.push(disponibilidad);
          } else if (item.Numeradas === '1') {
            this.butacaTipoNumerada.push(item.RecintoID);
          }
        });
        this.butacaTipoNoNumerada = [...new Set(this.butacaTipoNoNumerada)];
        this.butacaTipoNumerada = [...new Set(this.butacaTipoNumerada)];
        // 
      }
      this.httpPostEstadosButacasPorSesion().subscribe((res: any) => {
        this.listaButacasDisponibles = '';
        res.DatosResult.Butacas.forEach(item => {
          if (item.Estado == 2 || item.Estado == 4) {
            // TODO añadir un array con los posibles estados disponibles que devuelva /EstadosButacas
            item.IdsRelacion.forEach(
              subItem => (this.listaButacasDisponibles += subItem.IdRB + ',')
            );
          }
        });
        res.DatosResult.Butacas.forEach(item => {
          if (item.Estado == 2 || item.Estado == 4) {
            // TODO añadir un array con los posibles estados disponibles que devuelva /EstadosButacas
            item.IdsRelacion.forEach(
              subItem => (this.relistaButacasDisponibles.push(subItem.IdSvg))
            );
          }
        });
        this.listaButacasDisponibles = this.listaButacasDisponibles.slice(
          0,
          -1
        );

        this.setEstadosButacaValue(res.DatosResult.Butacas);
        this.getButacasMap();
        this.infoMapasService
          .getButacas(nombreRecinto)
          .subscribe((butacas: [any]) => {
            this.butacasConPrecio = butacas;
            this.estadosButacasValue.forEach(butacasConEstado => {
              butacasConEstado.IdsRelacion.forEach(cadaButaca => {
                this.butacasConPrecio.forEach(butacasDelJSON => {
                  if (cadaButaca.IdSvg === butacasDelJSON.idRecintoButaca) {
                    butacasDelJSON['datatipobutaca'] = cadaButaca.TipoBTC;
                  }
                });
              });
            });
            this.httpPostTodasButacasDetalles(
              this.listaButacasDisponibles
            ).subscribe((detalle: any) => {
              // recinto.selectAll('circle').remove();
              this.butacasConPrecio = this.butacasConPrecio.map(item => {
                const tarifas = [];
                const precios = [];
                detalle.DatosResult.forEach(x => {
                  if (item['datatipobutaca'] == x.TipoButacaId) {
                    tarifas.push(x);
                    precios.push(parseInt(x.PrecioInternet, 10));
                  }
                });
                item.tarifas = tarifas;
                return item;
              });
              this.butacas = butacas;

              // METER AQUI LO DE LAS NO NUMERADAS

              /*             this.butacaTipoNoNumerada.forEach(recintosNoNumerados => {
                recinto
                  .selectAll('[id="R' + recintosNoNumerados + '"]')
                  .style('fill', 'pink');
              }); */

              this.butacaTipoNoNumerada.forEach(recintosNoNumerados => {
                const disponibilidad = this.disponibilidadButacaTipoNoNumerada.filter(
                  item => item.recinto === recintosNoNumerados
                );
                if (disponibilidad[0].disponibilidad === '') {
                  recinto
                    .selectAll('[id="R' + recintosNoNumerados + '"]')
                    .style('fill', 'red');
                } else if (disponibilidad[0].disponibilidad !== '') {
                  recinto
                    .selectAll('[id="R' + recintosNoNumerados + '"]')
                    .style('cursor', 'pointer')
                    .style('stroke', function() {
                      return d3.select(this).style('fill');
                    })
                    .style('stroke-width', '2')
                    .on('mouseover', function() {
                      d3.select(this).style('fill-opacity', '0.6');
                    })
                    .on('mouseout', function() {
                      d3.select(this).style('fill-opacity', '1');
                    })
                    .on('click', () => {
                      let butacasIds = '';
                      const butacasDelRecinto = this.butacasConPrecio.filter(
                        butaca => butaca.dataIdRecinto == recintosNoNumerados
                      );
                      butacasDelRecinto.forEach(butaca => {
                        butacasIds =
                          butacasIds +
                          this.butacasMap[
                            this.generateId(
                              this.tokenManagerService.currentTokenValue
                                .sessionId,
                              butaca.idRecintoButaca
                            )
                            ] +
                          ',';
                      });
                      butacasIds = butacasIds.slice(0, -1);
                      // 
                      this.httpPostTodasButacasDetalles2(
                        butacasIds,
                        butacasDelRecinto[0]
                      ).subscribe((infoButaca: any) => {
                        const butacaNoNumerada = butacasDelRecinto[0];
                        butacaNoNumerada.info = infoButaca;
                        /* butacaNoNumerada.info.infoGeneral.idTipoSesion =
                          data.DatosResult[0].IDTipoSesion; */
                        butacaNoNumerada.info.infoGeneral.idTipoSesion =
                          data.DatosResult[0].IDTipoSesion;
                        this.setButacaMarcadaValue(butacaNoNumerada);
                        this.esNumerada.next(false);
                        this.httpPostButacaTarifasEstado(butacasIds).subscribe((resul: any) => {
                          if (resul && resul.DatosResult && resul.DatosResult.TarifasEstados.length > 1) {
                            resul.DatosResult.TarifasEstados.forEach(estadoTarifa => {
                              if (estadoTarifa.Estado == '0' && estadoTarifa.IdTarifas.length > 1) {
                                // 
                                estadoTarifa.IdTarifas.forEach(tarifa => {
                                  this.tarifaBloqueada.push(tarifa);
                                });
                              }
                            });
                          }

                          this.modalService.open('selectorTarifas');
                          this.butacaTipoNoNumerada = [];
                          this.butacaTipoNumerada = [];
                        });
                      });
                    })
                    .append('svg:title')
                    .append('svg:title').text(`Recinto no numerado.
                      Disponibles: ${disponibilidad[0].disponibilidad}`);
                }
              });
              this.butacasConPrecio.forEach(butaca => {
                //
                const tooltipText = `Fila: ${butaca.dataNumFila} - Butaca: ${butaca.dataNumButaca}`;
                const c = recinto
                  .append('circle')
                  .attr('_pkid', butaca.idRecintoButaca)
                  .style('fill', this.getColorButaca(butaca))
                  .style('display', () => {
                    return this.butacaTipoNoNumerada.includes(
                      butaca.dataIdRecinto
                    )
                      ? 'none'
                      : 'block';
                  })
                  .attr('cx', butaca.cx)
                  .attr('cy', butaca.cy)
                  .attr('r', butaca.r)
                  .on('mouseover', function() {
                    d3.select(this)
                      .insert('svg:title')
                      .text(tooltipText);
                  })
                  .on('mouseout', function() {
                    const listaHijos = this.childNodes;
                    const longitud = this.childNodes.length;
                    for (let index = 0; index < longitud; index++) {
                      this.removeChild(listaHijos[index]);
                    }
                  })
                  .on('click', () => {
                    // 
                    // 
                    if (this.relistaButacasDisponibles.find(element => element == butaca.idRecintoButaca)) {
                      if (this.comprobarSiButacaAgregada(butaca)) {
                        const b = this.entradasSeleccionadasValue.find(
                          e => e.idRecintoButaca === butaca.idRecintoButaca
                        );
                        this.httpPostMarcarDesmarcarButaca(b, false).subscribe(
                          () => {

                            this.removeButaca(b);
                            this.ventaService.removeEntrada(butacaToEntrada(b));
                          }
                        );
                      }
                      if (this.comprobarDisponibilidadButaca(recinto, butaca)) {
                        this.getInfoButaca(butaca).subscribe(
                          (infoButaca: any) => {
                            butaca.info = infoButaca;
                            // 
                            // 
                            data.DatosResult.forEach(item => {
                              if (item.RecintoID === butaca.dataIdRecinto) {
                                butaca.info.infoGeneral.idTipoSesion =
                                  item.IDTipoSesion;
                              }
                            });
                            butaca.DOMElement = c;
                            this.setButacaMarcadaValue(butaca);
                            // Llama a nuevo servicio ButacaTarifasEstado
                            this.tarifaBloqueada = [];
                            this.httpPostButacaTarifasEstado(butaca.info.infoGeneral.recintoButacaId).subscribe((resul: any) => {

                              if (resul.DatosResult.TarifasEstados[0].IdTarifas.length > 1) {
                                if (resul && resul.DatosResult && resul.DatosResult.TarifasEstados.length > 0) {
                                  resul.DatosResult.TarifasEstados.forEach(estadoTarifa => {
                                    if (estadoTarifa.Estado == '0' && estadoTarifa.IdTarifas.length > 0) {
                                      // 
                                      estadoTarifa.IdTarifas.forEach(tarifa => {
                                        this.tarifaBloqueada.push(tarifa);
                                      });
                                    }
                                  });
                                }
                                this.modalService.open('selectorTarifas');
                                this.butacaTipoNoNumerada = [];
                                this.butacaTipoNumerada = [];
                              }
                              if (resul.DatosResult.TarifasEstados[0].IdTarifas.length === 1) {
                                this.marcarTarifa(0);

                              }

                            });

                          }
                        );
                      }
                    }
                  });
              });
            });
          });
      });
    });
  }

  marcarTarifa(index) {

    this.butacaMarcadaValue.tipoSeleccionado = this.butacaMarcadaValue.info.tiposDeEntrada[
      index
      ];
    // 
    // 
    const tarifablock = this.tarifaBloqueada.find(x => x == this.butacaMarcadaValue.tipoSeleccionado.IdTarifa);
    if (tarifablock) {
      return;
    }
    //JS 20191228
    //this.butacaMarcada.fecha = this.espectaculoService.espectaculoSeleccionadoValue.dtFecha;
    //this.butacaMarcada.hora = this.espectaculoService.espectaculoSeleccionadoValue.dtHoraInicio;
    this.butacaMarcadaValue.fecha = this.espectaculoService.sesionSeleccionada.fechaSesion;
    this.butacaMarcadaValue.hora = this.espectaculoService.sesionSeleccionada.horaInicioSesion;
    //FJS
    // TODO: Por favor simplificame
    this
      .httpPostMarcarDesmarcarButaca(this.butacaMarcadaValue, true)
      .subscribe((res: any) => {
        if (
          res.DatosResult.IdRB.includes(
            Number.parseInt(
              this.butacaMarcadaValue.info.infoGeneral.recintoButacaId,
              10
            )
          )
        ) {
          const espectaculo = {
            ...this.espectaculoService.espectaculoSeleccionadoValue,
            fecha: this.espectaculoService.espectaculoSeleccionadoValue.dtFecha,
            hora: this.espectaculoService.espectaculoSeleccionadoValue
              .dtHoraInicio
          };
          this.espectaculoService.pushEspectaculo(espectaculo);
          const datosExtra = {
            idEspectaculoAsociado: this.espectaculoService
              .espectaculoSeleccionadoValue.iEspectaculoId,
            espectaculo: {
              ...this.espectaculoService.espectaculoSeleccionadoValue
            }
          };
          // 
          // 
          //JS 20191228 30
          datosExtra.espectaculo.dtFecha = this.espectaculoService.sesionSeleccionada.fechaSesion;
          datosExtra.espectaculo.dtHoraInicio = this.espectaculoService.sesionSeleccionada.horaInicioSesion;
          datosExtra.espectaculo.idRecinto = this.butacaMarcadaValue.dataIdRecinto;
          datosExtra.espectaculo.chNombreRecinto = this.butacaMarcadaValue.info.infoGeneral.nombreRecinto;
          datosExtra.espectaculo.idTipoSesion = this.butacaMarcadaValue.info.infoGeneral.idTipoSesion;
          //FJS
          const butaca = { ...this.butacaMarcadaValue, ...datosExtra };
          const entrada = butacaToEntrada(butaca);
          ;
          this.pushButaca(butaca);
          this.ventaService.sendEntrada(entrada);

          const infoSesion = {
            EsButaca: true,
            recinto: {
              ControlaAforo: '0'
            },
            // sesion: {
            //   Fecha: this.espectaculoService.espectaculoSeleccionadoValue
            //     .dtFecha,
            //   HoraInicio: moment(
            //     this.espectaculoService.espectaculoSeleccionadoValue
            //       .dtHoraInicio,
            //     'HH:mm:ss'
            //   ).format('HH:mm'),
            //   RecintoId: this.espectaculoService.espectaculoSeleccionadoValue
            //     .idRecinto
            // }
            //JS 20191228 30
            sesion: {
              Fecha: this.espectaculoService.sesionSeleccionada.fechaSesion,
              HoraInicio: moment(
                this.espectaculoService.sesionSeleccionada.horaInicioSesion,
                'HH:mm:ss'
              ).format('HH:mm'),
              RecintoId: this.butacaMarcadaValue.dataIdRecinto
              //FJS
            }
          };
          this.ventaService.carritoValue.horarios.push(infoSesion);
          this.butacaMarcadaValue.DOMElement.style('fill', 'blue');

          this.esNumerada.next(true);
        } else {
          // 
          this.getEstadosButacas(
            this.infoMapasService.recinto,
            this.espectaculoService.espectaculoSeleccionadoValue
              .chNombreFicheroSVG
          );
        }
      });
  }

  getButacasMap() {
    this.estadosButacasValue
      .map(estado => estado.IdsRelacion)
      .reduce((acc, val) => acc.concat(val), [])
      .forEach(butaca => {
        const id = this.generateId(
          this.tokenManagerService.currentTokenValue.sessionId,
          butaca.IdSvg
        );
        this.butacasMap[id] = butaca.IdRB;
      });
  }

  generateId(sessionId, svgId) {
    return sessionId + svgId;
  }

  getStatusButacaById(id: any) {
    for (let i = 0; i < this.estadosButacasValue.length; ++i) {
      const status = this.estadosButacasValue[i].Estado;
      const correctStatus = this.estadosButacasValue[i].IdsRelacion.find(
        function(findId: any) {
          return findId.IdSvg === id;
        }
      );
      if (correctStatus) {
        return status;
      }
    }
    return 3;
  }

  public addAsientoDeshabilitado(asiento) {
    const asientos = this.asientosDeshabilitadosValue;
    asientos.push(asiento);
    this.setAsientosDeshablitados(asientos);
  }

  comprobarSiButacaAgregada(butaca: any) {
    return this.entradasSeleccionadasValue
      .filter(
        entrada =>
          entrada.idEspectaculoAsociado ===
          this.espectaculoService.espectaculoSeleccionadoValue
            .iEspectaculoId &&
          entrada.fecha ===
          this.espectaculoService.sesionSeleccionada.fechaSesion &&
          entrada.hora ===
          this.espectaculoService.sesionSeleccionada.horaInicioSesion
      )
      .find(
        entrada =>
          butaca.idRecintoButaca === entrada.idRecintoButaca &&
          butaca.dataNumButaca === entrada.dataNumButaca &&
          butaca.dataNumFila === entrada.dataNumFila
      );
  }

  getInfoButaca(butaca) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      Idioma: 'es-ES',
      ConexionIacpos: conexionId,
      // RecintosButacasId: butaca.idRecintoButaca
      RecintosButacasId: this.butacasMap[
        this.generateId(
          this.tokenManagerService.currentTokenValue.sessionId,
          butaca.idRecintoButaca
        )
        ]
    };
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/ButacaInfo`, body)
      .pipe(
        mergeMap((res: any) =>
          this.http
            .post(`${this.config.getConfig('API_URL')}api/ButacaDetalle`, {
              ...body,
              chPlataforma: '2',
              Nombre: null,
              Contrasena: null,
              CodigoCliente: this.ventaService.currentCliente !== '0' ? this.ventaService.currentCliente : null
              // GruposInternetId: '',
              // chPlataforma: '2'
            })
            .pipe(
              map((detalles: any) => {
                const infoButaca = {
                  infoGeneral: res.DatosResult[0],
                  tiposDeEntrada: detalles.DatosResult
                };
                return infoButaca;
              })
            )
        )
      );
  }

  removeButaca(butaca: any): boolean {
    // const idsSeleccionados = this.entradasSeleccionadasValue
    // .filter( entrada => entrada.idEspectaculoAsociado === this.ventaService.espectaculoValue.EspectaculosId)
    // .map( e => +e.idRecintoButaca);
    // const minButaca = (Math.min(...idsSeleccionados)) === +butaca.idRecintoButaca;
    // const maxButaca = (Math.max(...idsSeleccionados)) === +butaca.idRecintoButaca;
    // if (idsSeleccionados.length > 0 && (!minButaca && !maxButaca)) {
    //   alert(this.translatePipe.transform('ERR_BUTACA_INTERMEDIA')); // `no puedes deseleccionar butacas intermedias`
    //   return false;
    // }
    // tslint:disable-next-line:max-line-length
    this.setEntradasSeleccionadasValue(
      this.entradasSeleccionadasValue.filter(
        e =>
          e.idRecintoButaca !== butaca.idRecintoButaca ||
          e.fecha !== butaca.fecha ||
          e.hora !== butaca.hora
      )
    );
    d3.select(`circle[_pkid = "${butaca.idRecintoButaca}" ]`).style(
      'fill',
      'green'
    );
    const entrada = butacaToEntrada(butaca);
    this.ventaService.removeEntrada(entrada);
    return true;
  }

  removeAllButaca(): void {
    this.setEntradasSeleccionadasValue([]);
  }

  comprobarDisponibilidadButaca(recinto, butaca: any) {

    const idsSeleccionados = this.entradasSeleccionadasValue
      .filter(
        entrada =>
          entrada.idEspectaculoAsociado ===
          this.espectaculoService.espectaculoSeleccionadoValue.iEspectaculoId
      )
      .map(e => +e.idRecintoButaca);
    const minButaca =
      Math.min(...idsSeleccionados) - 1 === +butaca.idRecintoButaca;
    const maxButaca =
      Math.max(...idsSeleccionados) + 1 === +butaca.idRecintoButaca;
    if (this.comprobarSiButacaAgregada(butaca)) {
      return false;
    }
    if (this.asientosDeshabilitadosValue.includes(+butaca.idRecintoButaca)) {
      this.toast.error('', this.translate.instant('ERROR_SOLO_BUTACAS_DISPONIBLES'), {
        toastComponent: ErrorToast
      });
      return false;
    }
    return true;
  }

  private getColorButaca(butaca): string {
    //TODO: Esta no es mi ubicacion, pero creo que en realidad no sirvo para nada
    this.setAsientosDeshablitados([]);
    const status = this.getStatusButacaById(butaca.idRecintoButaca);
    if (this.comprobarSiButacaAgregada(butaca) !== undefined) {
      return 'blue';
    }

    for (
      let indexEstado = 0;
      indexEstado < this.configurationService.estadosButacas.length;
      indexEstado++
    ) {
      const itemEstado = this.configurationService.estadosButacas[indexEstado];
      if (
        status === itemEstado.EstadoButaca &&
        (itemEstado.TipoButaca === null ||
          butaca['datatipobutaca'] === itemEstado.TipoButaca)
      ) {
        if (
          +itemEstado.ClicTaquilla === 0 ||
          +itemEstado.MostrarTaquilla === 0 ||
          butaca.tarifas.length === 0
        ) {
          //
          this.addAsientoDeshabilitado(+butaca.idRecintoButaca);
        }
        return itemEstado.Color;
      }
    }
    return '#000000';
  }

  httpPostGetTipoButacasPorRecinto() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Idioma: 'es-ES',
      EspectaculoId: this.espectaculoService.sesionSeleccionada.espectaculoId,
      RecintosId: '',
      FechaSesion: this.espectaculoService.sesionSeleccionada.fechaSesion,
      HoraInicioSesion: this.espectaculoService.sesionSeleccionada
        .horaInicioSesion,
      SesionInternetId: this.turnoService.turnoValue.TpvId,
      GrupoRecintoId: this.espectaculoService.espectaculoSeleccionadoValue
        .pkIdGR,
      Plataforma: '2'
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/GetTipoButacasPorRecinto`,
      body
    );
  }

  httpPostDesamarcarButacasBySesionId() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Accion: 0,
      RecintosSesionesId: 0,
      IdEntrada: 0,
      SesionInternetId: this.turnoService.turnoValue.TpvId
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/DesamarcarButacasBySesionId`,
      body
    );
  }

  httpPostTodasButacasDetalles2(butacas: any, butaca) {
    //
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const bodyInfo = {
      Idioma: 'es-ES',
      ConexionIacpos: conexionId,
      RecintosButacasId: this.butacasMap[
        this.generateId(
          this.tokenManagerService.currentTokenValue.sessionId,
          butaca.idRecintoButaca
        )
        ]
    };
    const body = {
      Idioma: 'es-ES',
      chPlataforma: '2',
      ConexionIacpos: conexionId,
      RecintosButacasId: butacas,
      Nombre: null,
      Contrasena: null,
      CodigoCliente: this.ventaService.currentCliente !== '0' ? this.ventaService.currentCliente : null
    };
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/ButacaInfo`, bodyInfo)
      .pipe(
        mergeMap((res: any) =>
          this.http
            .post(`${this.config.getConfig('API_URL')}api/ButacaDetalle`, body)
            .pipe(
              map((detalles: any) => {
                // 
                detalles.DatosResult.forEach(item => {
                  this.configurationService.estadosButacas.forEach(data => {
                    if (data.TipoButaca === item.TipoButacaId && data.MostrarTaquilla == 1 && data.ClicTaquilla == 1) {
                      item.DescripcionTipo = data.Descripcion;
                    }
                  });
                });
                const infoButaca = {
                  infoGeneral: res.DatosResult[0],
                  tiposDeEntrada: detalles.DatosResult
                };
                return infoButaca;
              })
            )
        )
      );
  }

  httpPostTodasButacasDetalles(butacas: any) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      Idioma: 'es-ES',
      ConexionIacpos: conexionId,
      RecintosButacasId: butacas,
      Nombre: null,
      Contrasena: null,
      chPlataforma: '2',
      CodigoCliente: this.ventaService.currentCliente !== '0' ? this.ventaService.currentCliente : null
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/ButacaDetalle`,
      body
    );
  }

  httpPostMarcarDesmarcarButaca(butaca: any, marcar, idRB?) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Marcar: marcar ? '1' : '0',
      Taquilla: '1',
      chPlataforma: '2',
      SesionInternetId: this.turnoService.turnoValue.TpvId,
      Marcado: [
        {
          idtarifa: butaca.tipoSeleccionado.IdTarifa,
          // RecintoButacaIds: butaca.idRecintoButaca
          RecintoButacaIds: this.butacasMap[
            this.generateId(
              this.tokenManagerService.currentTokenValue.sessionId,
              butaca.idRecintoButaca
            )
            ]
        }
      ]
    };
    if (idRB !== undefined) {
      body.Marcado[0].RecintoButacaIds = idRB;
    }
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/MarcarDesmarcarButaca`,
      body
    );
  }

  httpPostMarcarDesmarcarButacaNoNumeradas(marcar, tarifa, recinto) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const espectaculo = this.espectaculoService.espectaculoSeleccionadoValue;
    const body = {
      ConexionIacpos: conexionId,
      // RecintoId: espectaculo.idRecinto, //JS 20191231
      RecintoId: recinto,
      EspectaculoId: espectaculo.iEspectaculoId,
      // FechaEspectaculo: moment(espectaculo.dtFecha, 'YYYYMMDD').format(
      //   'YYYY/MM/DD'
      // ), //JS20191230 1720
      FechaEspectaculo: moment(
        this.espectaculoService.sesionSeleccionada.fechaSesion,
        'YYYYMMDD'
      ).format('YYYY/MM/DD'),
      //HoraEspectaculo: espectaculo.dtHoraInicio, //JS20191230 1720
      HoraEspectaculo: this.espectaculoService.sesionSeleccionada
        .horaInicioSesion,
      Marcar: marcar,
      Taquilla: '1',
      Cantidad: '1',
      TipoButaca: tarifa.TipoButacaId,
      SesionInternetId: this.turnoService.turnoValue.TpvId,
      Marcado: [
        {
          idTarifa: tarifa.IdTarifa,
          Cantidad: '1'
        }
      ]
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/MarcarDesmarcarNoNumerada`,
      body
    );
  }

  httpPostEstadosButacasPorSesion() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Idioma: 'es-ES',
      EspectaculoId: this.espectaculoService.sesionSeleccionada.espectaculoId,
      RecintosId: '',
      RDGIds: '',
      FechaSesion: this.espectaculoService.sesionSeleccionada.fechaSesion,
      HoraInicioSesion: this.espectaculoService.sesionSeleccionada
        .horaInicioSesion,
      SesionesInternetId: this.turnoService.turnoValue.TpvId,
      Plataforma: '2'
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/EstadosButacasPorSesion`,
      body
    );
  }

  httpPostButacaTarifasEstado(recintoButacaId) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Idioma: 'es-ES',
      RecintoButacaId: recintoButacaId,
      GruposInternet: '',
      Plataforma: '2',
      CodigoCliente: this.ventaService.clienteSeleccionadoValue ? this.ventaService.clienteSeleccionadoValue.Codigo : 0
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/ButacaTarifasEstado`,
      body
    );
  }
}
