export const errorMessages = {
    204: 'No Content',
    400: 'Bad Request',
    401: 'Unauthorized',
    404: 'Not Found',
    500: 'Internal Server Error',
    default: 'Unknown error',
  };
  
  export function getErrorMessage(status: number): string {
    return errorMessages[status] || errorMessages.default;
  }