import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { EntradasService } from '../../services/entradas.service';

import { Entrada } from '../../models/entrada.model';
import { mergeMap, map, takeWhile } from 'rxjs/operators';
import { of } from 'rxjs';
import { VentaService } from '../../services/venta.service';
import { Carrito } from '../../models/carrito.model';
import { ConfigurationService } from 'src/app/core';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { MotivosService } from '../../services/motivos.service';
import { MotivoPadService } from 'src/app/shared/services/motivo-pad.service';
import { StepperNavigationService } from '../../services/stepper-navigation.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { NumpadService } from 'src/app/shared/services/numpad.service';

@Component({
  selector: 'ticketing-modal-selector-motivos',
  templateUrl: './modal-selector-motivos.component.html',
  styleUrls: ['./modal-selector-motivos.component.scss']
})
export class ModalSelectorMotivosComponent implements OnInit, OnDestroy {
  entrada;
  @Input() id: string;

  private element: any;

  pager: any = {};
  pagedItems: any[];

  carrito;
  divisaSimboloDecimal: string; 
  divisaSimbSeparacionMiles: string;  
  divisaSimbolo: string;
  divisaDecimales: number;
  divisaPosicion: string;
  divisaSimboloDerecha: string;
  esGranDivisa: boolean;

  motivoSeleccionado;

  listaMotivosSeleccionados;

  motivos;

  private alive = true;

  constructor(
    private entradaService: EntradasService,
    private modalService: ModalService,
    private ventaService: VentaService,
    private configuration: ConfigurationService,
    private numpadService: NumpadService,
    private formControlService: FormControlService,
    private motivosService: MotivosService,
    private motivoPadService: MotivoPadService,
    private stepperNavigation: StepperNavigationService,
    private el: ElementRef,
    private configurationService: ConfigurationService,
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.divisaSimbSeparacionMiles = this.configurationService.divisasValue[0].SimbSeparacionMiles;
    this.divisaSimboloDecimal = this.configurationService.divisasValue[0].SimboloDecimal;
    this.divisaSimbolo = this.configurationService.divisasValue[0].simbolo;
    this.divisaDecimales = Number(this.configurationService.divisasValue[0].NumeroDecimales)
    this.divisaPosicion = this.configurationService.divisasValue[0].PosicionDivisa;
    this.divisaSimboloDerecha = this.configuration.divisasValue[0].SimboloDerecha;
    this.ventaService.carrito.pipe(takeWhile(() => this.alive)).subscribe(carrito => {
      this.carrito = carrito;
    });
    // 
    this.entradaService.entradaSeleccionada$
      .pipe(
        mergeMap(entrada => {
          // ;
          this.entrada = entrada;
          if (entrada) {
             //<< Yaribel 20210504  Se ha modificado el codigo para indicar GrupoMotivoId tanto en entradas como en promociones y solo tiene valor si tiene motivos seleccionables                   
            
             return this.entradaService.getDescuentos(entrada.GrupoMotivoId,1);
            // if (entrada.TipoProducto === '2') {
            //   let motivoId = 0;
            //   entrada.PromocionEntradas.forEach(element => {
            //     if (element.GrupoMotivoId != '') {
            //       motivoId = element.GrupoMotivoId;
            //     }
            //   });
            //   return this.entradaService.getDescuentos(motivoId,1); //Yaribel 20201224 Añadimos solicitar solo los seleccionables 
            // } else {
            //   return this.entradaService.getDescuentos(entrada.GrupoMotivoId,1); //Yaribel 20201224 Añadimos solicitar solo los seleccionables 
            // }

             // Yaribel 20210504  Se ha modificado el codigo para indicar GrupoMotivoId tanto en entradas como en promociones y solo tiene valor si tiene motivos seleccionables >>
          } else {
            return of(entrada);
          }
        })
      )
      .pipe(
        mergeMap(res => {
          if (this.entrada) {
            // 
            return this.motivosService
              .getMotivosSeleccionadosByEntradaId(this.entrada.idTarifa)
              .pipe(
                map(motivosSeleccionados => {
                  if (motivosSeleccionados) {
                    this.listaMotivosSeleccionados = motivosSeleccionados.lista;
                  }
                  return res;
                })
              );
          } else {
            return of(res);
          }
        })
      )
      .pipe(takeWhile(() => this.alive))
      .subscribe(motivos => {
        // 
        // 
        this.motivos = motivos;
      });
    const modal = this;
    if (!this.id) {
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        
        modal.close();
      }
    });
    this.modalService.add(this);
  }


  ngOnDestroy(): void {

    this.alive = false;
    this.modalService.remove(this.id);
    this.element.remove();
  }

  open(): void {
    
    this.formControlService.unfocus();
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  avanzar() {
    this.close();
  }

  getPrecioTotal() {
    return this.motivosService.getPrecioTotal();
  }

  getCantidadTotal() {
    return this.motivosService.getCantidadTotal();
  }

  close(): void {
    
    if (this.modalService.isOpen('selector-mas-entradas')) {
      this.modalService.close('selector-mas-entradas');
    }
    this.formControlService.unfocus();
    this.entrada = undefined;
    this.listaMotivosSeleccionados = [];
    this.motivoSeleccionado = undefined;
    this.motivoPadService.motivo = undefined;
    this.motivoPadService.selected = undefined;
    this.element.style.display = 'none';
    this.motivos = [];
    document.body.classList.remove('jw-modal-open');
  }

  selectMotivo(motivo) {
    this.motivoSeleccionado = motivo;
    this.motivoSeleccionado.idEntradaAsociada = this.entrada.idTarifa;
    // this.entrada.motivos.push(motivo);
    const input = document.getElementById(`motivo-${this.motivoSeleccionado.PkId}`);
    this.motivoPadService.selected = input;
    this.motivoPadService.motivo = this.motivoSeleccionado;
    this.formControlService.setCurrentFormGroup(null, input, input.getAttribute('category'));
    if ((<HTMLElement>event.target).getAttribute('category') === 'MOTIVO') {
      this.numpadService.commaDisabled = true;
    } else {
      this.numpadService.commaDisabled = false;
    }
  }

  getCantidadPorMotivo(id) {
    if (this.listaMotivosSeleccionados) {
      return this.listaMotivosSeleccionados.find(m => m.PkId === id)
        ? this.listaMotivosSeleccionados.find(m => m.PkId === id).numMotivo
        : '';
    } else {
      return '';
    }
  }

  eliminarMotivo(motivo) {
    const oldVals = {
      motivoSeleccionado: this.motivoPadService.motivo,
      selected: this.motivoPadService.selected
    };
    this.motivoSeleccionado = motivo;
    this.motivoSeleccionado.idEntradaAsociada = this.entrada.idTarifa;
    const input = document.getElementById(`motivo-${this.motivoSeleccionado.PkId}`);
    this.motivoPadService.selected = input;
    this.motivoPadService.motivo = this.motivoSeleccionado;
    this.formControlService.setCurrentFormGroup(null, input, input.getAttribute('category'));
    this.formControlService.setToBlank();

    if (oldVals.motivoSeleccionado) {
      this.motivoSeleccionado = oldVals.motivoSeleccionado;
    }
    if (oldVals.selected) {
      this.motivoPadService.selected = oldVals.selected;
    }

    this.motivoPadService.motivo = this.motivoSeleccionado;
    this.formControlService.setCurrentFormGroup(
      null,
      this.motivoPadService.selected,
      this.motivoPadService.selected.getAttribute('category')
    );
    // this.numpadService.entrada = entradaOriginal;
  }

  focusValue(event: any, motivo) {
    this.motivoSeleccionado = motivo;
    event.srcElement.value = '';
    this.motivoPadService.selected = event.srcElement;
    this.motivoPadService.motivo = motivo;
    this.formControlService.setCurrentFormGroup(
      null,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  unfocusValue(event: any) {
    if (event.srcElement.value === '') {
      this.motivoPadService.setNumpadOrder('reset');
      return;
    }
  }

  inputDeTeclado(event: any, entrada: Entrada) {
    event.preventDefault();
    const charCode = event.keyCode;
    this.motivoPadService.selected = event.srcElement;
    this.motivoPadService.motivo = entrada;
    this.formControlService.setCurrentFormGroup(
      null,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );

    if (this.isDeleteButtonPressed(charCode)) {
      this.formControlService.deleteChar();
      return;
    }

    if (this.isEnterButton(charCode)) {
      this.formControlService.unfocus();
      event.srcElement.blur();
      return;
    }

    if (this.isNumberKey(charCode)) {
      this.formControlService.inputChar(event.key);
      return;
    }
  }

  isNumberKey(charCode: number) {
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  isDeleteButtonPressed(charCode: number) {
    return charCode === 8 ? true : false;
  }

  isEnterButton(charCode: number) {
    return charCode === 13 ? true : false;
  }
}
