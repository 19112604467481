import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Token } from '../token.model';
import { map } from 'rxjs/operators';
import { AppConfiguration } from 'src/app/app.configuration';

interface RequestBody {
  nombre: string;
  contrasena: string;
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class TokenManagerService {
  currentConexionIdSubject: Subject<string> = new Subject();
  tokenRecibido: Subject<boolean> = new Subject();

  private currentTokenSubject: BehaviorSubject<Token>;
  currentToken: Observable<Token>;

  constructor(private http: HttpClient, private config: AppConfiguration) {
    this.currentTokenSubject = new BehaviorSubject<Token>(
      JSON.parse(localStorage.getItem('currentToken'))
    );
    this.currentToken = this.currentTokenSubject.asObservable();
  }

  public get currentTokenValue(): Token {
    return this.currentTokenSubject.value;
  }


  // login(nombre: string, contrasena: string): Observable<any> {
  //   const credenciales = this.generarCredencialesCifradasRegistro(nombre, contrasena);
  login(numUser: string): Observable<any> {
    const credenciales = {
      nombre: numUser,
      id: this.generarSessionId()
    };
    return this.http.post(`${this.config.getConfig('API_URL')}api/IniciarLog`, credenciales).pipe(
      map((res: any) => {
        const token = res.DatosResult;
        const currentSession: Token = {
          sessionId: credenciales.id,
          token: token
        };
        localStorage.setItem('currentToken', JSON.stringify(currentSession));
        localStorage.setItem('credentialId', credenciales.id);
        this.currentTokenSubject.next(currentSession);
        this.tokenRecibido.next(true);
        return currentSession;
      })
    );
  }

  logout(): void {
    localStorage.removeItem('currentToken');
    localStorage.clear();
    this.currentTokenSubject.next(null);
  }

  private generarSessionId(): string {
    const valorFechaActual = new Date().valueOf();
    const valorAleatorio = Math.random()
      .toString()
      .slice(-5);
    return '' + valorAleatorio + valorFechaActual;
  }

  private generarCredencialesRegistro(nombre: string, contrasena: string): RequestBody {
    const credencialesRegistro: RequestBody = {
      nombre: nombre,
      contrasena: contrasena,
      id: this.generarSessionId()
    };
    return credencialesRegistro;
  }

  private generarCredencialesCifradasRegistro(nombre: string, contrasena: string) {
    const salt = '$A$';
    const trozosNombre = this.trocear(nombre);
    const trozosClave = this.trocear(contrasena);
    const clave =
      trozosNombre[0] + salt + trozosClave[0] + salt + trozosNombre[1] + salt + trozosClave[1];
    const credencialesRegistro = {
      nombre: clave,
      id: this.generarSessionId()
    };
    return credencialesRegistro;
  }

  private trocear(string) {
    const mitad = Math.ceil(string.length / 2);
    return [string.slice(0, mitad), string.slice(mitad)];
  }
}
