import { Injectable } from '@angular/core';
import { User, Permiso } from '../user.model';
import { ToastrService } from 'ngx-toastr';
import { ErrorToast } from '../toast/error.toast';
import { UsuarioElevadoService } from './usuario-elevado.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { TranslateService } from '../../shared/services/translate.service';

export interface AccionConfig {
  toast: boolean;
  elevarUsuario: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PermisosService {
  accion;

  constructor(
    private translate: TranslateService,
    private toast: ToastrService,
    private modalService: ModalService,
    private usuarioElevadoService: UsuarioElevadoService
  ) {
  }

  puedeAccionar(
    usuario: User,
    accion: string,
    conf: AccionConfig = { toast: true, elevarUsuario: false }
  ) {
    if (usuario && usuario.Permisos) {
      const permiso = usuario.Permisos.find(
        (p: Permiso) => p.NombreAccion === accion
      );
      this.accion = accion;
      if (permiso) {
        return true;
      } else {
        if (conf.toast) {
          this.toast.error(
            '',
            this.translate.instant('NO_PERMISOS_ACCION'),
            {
              toastComponent: ErrorToast,
              timeOut: 5000
            });
        }
        if (conf.elevarUsuario) {
          this.modalService.open('elevarUsuario');
        }
      }
    }
  }

  reducir() {
    this.usuarioElevadoService.reducir();
  }
}
